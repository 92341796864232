import React from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { PACKAGE_NAMES } from 'Customers/constants';
import SelectField from 'components/SelectField';
import TextInput from 'components/forms/TextInput/TextInput';
import RoundedButton from 'components/RoundedButton/RoundedButton';

const EditSubscriptionForm = ({ onClose, onSubmit, isLoading, initialValues, selected, users }) => {
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      price: yup.number().positive('price must be positive').required('price is required'),
      numberOfUnits: yup.number().positive('price must be positive').required('price is required'),
      implementationUser: selected.requiresUser
        ? yup.string().required('Assign User is required')
        : yup.string().nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const submissionValues = {
        price: values.price,
        numberOfUnits: values?.numberOfUnits || 0,
        implementationUser: values?.implementationUser || '',
      };
      onSubmit(submissionValues).finally(() => setSubmitting(false));
    },
  });

  const renderPlaceholder = () => (
    <div className='d-flex align-items-center gap-1'>
      <span>
        <i className='icon-user-circle me-2' />
        Select
      </span>
    </div>
  );
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className='row mx-3'>
        <div className='col-md-12 my-3'>
          <TextInput
            name='plan'
            label='package'
            className='font-size-16'
            value={PACKAGE_NAMES[selected.plan] || selected.plan}
            readOnly
          />
        </div>
        {selected.pricingModel === 'PER_UNIT' && (
          <div className='col-md-12 mb-3'>
            <TextInput
              name='numberOfUnits'
              label='number of units'
              type='number'
              value={formik.values.numberOfUnits}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isRequired
              error={formik.touched.numberOfUnits && formik.errors.numberOfUnits}
            />
          </div>
        )}
        <div className='col-md-6 mb-3'>
          <TextInput
            name='price'
            label='unit Price'
            type='number'
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isRequired
            error={formik.touched.price && formik.errors.price}
          />
        </div>
        <div className='col-md-6 mb-3'>
          <SelectField
            isCustom
            label='Currency'
            options={[
              {
                id: selected.currency,
                name: selected.currency,
                option: selected.currency,
                value: selected.currency,
              },
            ]}
            value={selected.currency}
            isDisabled
            isRequired
          />
        </div>
        {selected.requiresUser && (
          <div className='col-md-12 mb-3'>
            <SelectField
              isCustom
              name='implementationUser'
              placeholder={renderPlaceholder()}
              label='Assign User'
              options={users}
              value={formik.values.implementationUser}
              onChange={formik.handleChange}
              error={formik.touched.implementationUser && formik.errors.implementationUser}
              isRequired
            />
          </div>
        )}
      </div>
      <div className={cx('d-flex', 'justify-content-end gap-2', 'py-2 my-2', 'pe-4', 'border-t')}>
        <RoundedButton text='cancel' btnStyle='contained' color='white' size='md' onClick={onClose} />
        <RoundedButton loading={isLoading} type='submit' text='Save' btnStyle='contained' color='primary' size='md' />
      </div>
    </form>
  );
};

export default EditSubscriptionForm;
