import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { useQueryClient } from '@tanstack/react-query';
import snakeCase from 'lodash/snakeCase';
import { queryKeys, useQueryCustomers } from '../hooks/useCustomers';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import useDebounce from 'Shared/useDebounce';
import Search from 'components/Search';
import styles from './Customers.module.scss';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import CustomersTable from './components/CustomersTable/CustomersTable';
import IconButton from 'components/IconButton';
import CustomersCards from './components/CustomersCards/CustomersCards';
import AddCustomerModal from '../Modal/AddCustomerModal/AddCustomerModal';

const Customers = () => {
  const queryClient = useQueryClient();
  const isAsc = useRef(false);

  const [view, setView] = useState('list');
  const [search, setSearch] = useState('');
  const [addCustomerModal, setAddCustomerModal] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  const [params, setParams] = useState({
    status: 'active',
    currentPage: 1,
    perPage: 5,
    search: '',
    sort: '-started',
  });

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearch }));
  }, [debouncedSearch]);

  const { isLoading: isLoadingCustomers, data } = useQueryCustomers({
    ...params,
    status: snakeCase(params.status),
  });

  const customersData = data?.data?.items || [];

  const showSearch =
    isLoadingCustomers ||
    (!search && customersData.length > 0) ||
    (search && customersData.length === 0) ||
    (search && customersData.length > 0);

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const updateList = () => {
    queryClient.invalidateQueries(queryKeys.customers(params));
  };

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const toggleView = () => {
    setView(view === 'list' ? 'grid' : 'list');
  };
  const OpenModal = () => {
    setAddCustomerModal(true);
  };

  return (
    <div className='mt-3 mx-3'>
      <div className={cx(styles.wrapper, 'panel p-0')}>
        <div className='px-5 py-4 border-btm d-flex align-items-center justify-content-between'>
          <div>
            <h2>Customers</h2>
            <p className='tab-subtitle'>List of all customers and their details</p>
          </div>
          <RoundedButton icon='icon-plus' text='Add New' color='primary' size='sm' onClick={OpenModal} />
        </div>
        {showSearch && (
          <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between px-5'>
            <div className='col-4 p-2'>
              <Search
                className='p-0'
                onChange={(e) => {
                  setSearch(e.target.value.replace(/^\s+/g, ''));
                }}
                placeholder='Search customer'
                value={search}
                onClear={() => {
                  setSearch('');
                }}
              />
            </div>
            <IconButton size='md' icon={view === 'list' ? 'icon-grid' : 'icon-grid-row-3'} onClick={toggleView} />
          </div>
        )}
        <div style={{ height: 'calc(100vh - 190px)' }} className='mx-5'>
          {view === 'list' ? (
            <div className='h-100 d-flex justify-content-start flex-column gap-2'>
              <CustomersTable
                onSort={onSort}
                isLoading={isLoadingCustomers}
                customers={customersData}
                updateList={updateList}
                addNew={OpenModal}
                withSearch={Boolean(search)}
              />
              {data?.data?.pagination?.total > 0 && (
                <PHRPagination onPaginationAction={updatePaginationParams} pagination={data?.data?.pagination} />
              )}
            </div>
          ) : (
            <CustomersCards
              pagination={data?.data?.pagination}
              updatePaginationParams={updatePaginationParams}
              customersData={customersData}
              isLoading={isLoadingCustomers}
              withSearch={Boolean(search)}
              addNew={OpenModal}
            />
          )}
        </div>
      </div>
      <AddCustomerModal
        isOpen={addCustomerModal}
        onClose={() => {
          setAddCustomerModal(false);
        }}
      />
    </div>
  );
};

export default Customers;
