const PublicRoutes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

const PrivateRoutes = {
  dashboard: 'dashboard',
  translations: 'translations',
  customers: 'customers',
  customersAccounts:'customers/:customerID/accounts',
  announcements: 'announcements',
  anchors: 'anchors',
  insights: '/insights',
  insightsSubscription: '/insights/subscriptions',
  insightsHeadcount: '/insights/headcount',
  feedback: 'feedback',
  careers: 'careers',
  quickPanel: 'quick-panel',
  users: 'users',
  templates: 'templates/*',
};

export { PublicRoutes, PrivateRoutes };
