import React, { useState } from 'react';
import cx from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import Tooltip from 'components/Tooltip/Tooltip';
import NavItem from './NavItem';
import IconButton from 'components/IconButton';

import styles from './NavItem.module.scss';

const PlatformMenu = ({ onClick, active, isMinimized, isAdmin, tooltip, currentView }) => {
  const [showContent, setShowContent] = useState(false);

  const handleToggle = () => setShowContent(!showContent);

  const renderNavItem = (key, icon, title, condition = true) =>
    condition && (
      <NavItem
        onClick={() => onClick(key)}
        icon={icon}
        title={title}
        isMinimized={isMinimized}
        active={currentView === key}
        tooltip={title}
      />
    );

  const btnContent = (
    <div className={cx(styles.link, { [styles.active]: active }, 'nav-link w-100 py-2 px-1 d-flex align-items-center')}>
      <span className={cx(styles.iconWrapper, 'position-relative')}>
        <i className='icon-asset-10' />
      </span>
      {!isMinimized && <span className={cx(styles.text, 'd-flex justify-content-between ps-2 w-100')}>Platform</span>}
    </div>
  );

  return (
    <>
      <div className={cx(styles.listItem, 'd-flex justify-content-between align-items-center')} onClick={handleToggle}>
        {isMinimized ? (
          <Tooltip className='ms-2' label={tooltip}>
            {btnContent}
          </Tooltip>
        ) : (
          btnContent
        )}

        {!isMinimized && (
          <IconButton
            icon={showContent ? 'icon-chevron-top' : 'icon-chevron-bottom'}
            size='md'
            color='gray'
            onClick={handleToggle}
          />
        )}
      </div>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={showContent}
        timeout={200}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
      >
        <div className={cx('gap-2 d-flex flex-column mb-1', isMinimized ? '' : 'mx-3')}>
          {renderNavItem('translations', 'icon-note-text', 'Translations')}
          {renderNavItem('announcements', 'icon-rocket', 'Release Note', isAdmin)}
          {renderNavItem('anchors', 'icon-connected', 'Anchors')}
          {renderNavItem('feedback', 'icon-megaphone', 'Feedback')}
        </div>
      </CSSTransition>
    </>
  );
};

export default PlatformMenu;
