import React from 'react';

import { PHRCheckbox } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const Checkbox = ({
  name,
  value,
  label,
  checked,
  onChange,
  blur,
  className,
  size,
  borderColor,
  checkedColor,
  backgroundColor,
}) => (
  <PHRCheckbox
    name={name}
    value={value}
    label={label}
    checked={checked}
    onChange={onChange}
    blur={blur}
    className={className}
    size={size}
    borderColor={borderColor}
    checkedColor={checkedColor}
    backgroundColor={backgroundColor}
  />
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  blur: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  borderColor: PropTypes.string,
  checkedColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  blur: () => {},
  className: '',
  label: '',
  size: '20px',
  borderColor: '#ccc',
  checkedColor: '#1A938A',
  backgroundColor: 'transparent',
};

export default Checkbox;
