import http from './Api';
import stringUtility from '../utility/stringUtility';

class ClientsApi {
  static getCustomerDetails(tenant) {
    return http.get(`customers/${tenant}/headcount-details`);
  }

  static getTotals() {
    return http.get('clients/numbers');
  }

  // *** Customers *** //
  static getCustomers(params) {
    return http.get('/plg/customers', { params });
  }

  static getCustomerById(id) {
    return http.get(`/plg/customers/${id}`);
  }

  static getCustomersAccounts(id, params) {
    return http.get(`/plg/customers/${id}/accounts`, { params });
  }

  static getInsightCustomers(params) {
    return http.get(`insights/subscriptions`, { params });
  }

  static getCustomerInsightDetails(id) {
    return http.get(`insights/subscriptions/tenant/${id}`);
  }

  static getTenant(id) {
    return http.get(`/clients/tenant/${id}`);
  }

  static getTenantSubscriptions(id, params) {
    return http.get(`/plg/subscriptions/${id}`, { params });
  }

  static ActivateTenantSubscription({ id, data }) {
    return http.put(`/subscription/${id}/activate`, data);
  }

  static DeactivateTenantSubscription({ id, data }) {
    return http.put(`/subscription/${id}/deactivate`, data);
  }

  static updateTenantSubscription({ id, data }) {
    return http.put(`/subscription/${id}/edit`, data);
  }

  static createTenant({ id, data }) {
    return http.post(`/plg/tenants/${id}`, data);
  }

  static getPlans() {
    return http.get('clients/subscription/plans');
  }

  static getPlansList(country) {
    return http.get(`/plg/plans/${country}?currentPage=1&perPage=9999`);
  }

  static getAddonPlans() {
    return http.get('clients/subscription/plans');
  }

  static getAddons() {
    return http.get('clients/subscription/addons');
  }

  static getCoupons() {
    return http.get('clients/subscription/addons');
  }

  static createCustomer(data) {
    return http.post('/plg/customers', data);
  }

  static updateCustomer({ id, data }) {
    return http.put(`/plg/customers/${id}`, data);
  }

  static addSubscription(data, customerId) {
    return http.post(`customers/${customerId}/subscriptions`, data);
  }

  static upgradeToPro(customer, data) {
    return http.post(`clients/tenant/${customer}/upgrade`, data);
  }

  static createSuperAdmin({ id, data }) {
    return http.post(`clients/tenant/${id}/create-admin`, data);
  }

  // *** Customers End *** //

  // *** Clients *** //
  static getClients(params, pagination, search = undefined) {
    // const queryString = stringUtility.toQueryString(pagination);
    // if (search) {
    //   return http.get(`clients${params}&${queryString}&search=${search}`);
    // }

    return http.get(`demos`, { params: { ...params, ...pagination, search } });
  }

  static getDemoClient(id) {
    return http.get(`demos/${id}`);
  }

  static saveData(id, data) {
    return http.put(`/demos/${id}`, data);
  }

  static createClient(data) {
    return http.post(`/demos`, data);
  }

  static suspendClient(client) {
    return http.put(`demos/${client}/suspend`);
  }

  static archiveClient(client) {
    return http.put(`demos/${client}/archive`);
  }

  static activateClient(client) {
    return http.put(`demos/${client}/activate`);
  }

  static checkIsDomainAvalable(domain) {
    return http.get(`utility/domain-available?domain=${domain}`);
  }

  static extendTrial(customer, data) {
    return http.put(`clients/tenant/${customer}/extend-trial`, data);
  }

  static deleteCustomer(customer) {
    return http.delete(`customers/${customer}`);
  }

  static confirmBilling(customer) {
    return http.put(`clients/tenant/${customer}/confirm-billing`);
  }

  static suspendCustomer(customer) {
    return http.put(`clients/tenant/${customer}/suspend`);
  }

  static archiveCustomer(customer) {
    return http.put(`clients/tenant/${customer}/archive`);
  }

  static computeStatus(customer) {
    return http.put(`clients/tenant//${customer}/compute-status`);
  }

  static cancelSubscription(name) {
    return http.put(`plg/tenants/tenant/name/${name}/cancel`);
  }

  static activateTenant(id) {
    return http.put(`plg/tenants/${id}/activate`);
  }

  static reactivateSubscription(customer) {
    return http.put(`clients/tenant/${customer}/reactivate`);
  }

  static resumeSubscription(customer) {
    return http.put(`clients/tenant/${customer}/resume`);
  }

  static retryFailedCustomer(customer) {
    return http.put(`clients/tenant/${customer}/retry`);
  }

  static syncSubscription(customer) {
    return http.put(`clients/tenant/${customer}/sync-subscription`);
  }

  static getPalmHireCustomers(params) {
    return http.get('customers/palm-hire/list', { params });
  }

  static getPalmConciergeCustomers(pagination, search = '') {
    return http.get(`customers/palm-concierge/list`, { params: { ...pagination, search } });
  }

  static getCountries() {
    return http.get('/countries?currentPage=1&perPage=9999');
  }
}

export default ClientsApi;
