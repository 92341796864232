import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useFormikWizard } from 'formik-wizard-form';
import isEmpty from 'lodash/isEmpty';
import { Scrollbars } from 'react-custom-scrollbars';

import WizardFooter from './WizardFooter';

const PhrWizardWithoutSideMenu = ({
  initialValues,
  renderHeaderComponent,
  onSubmit,
  generateWizardSteps,
  onNext,
  submitButtonText,
  className,
  updateKey,
}) => {
  const [steps, setSteps] = useState(generateWizardSteps(initialValues));
  const scrollbarsRef = useRef();

  const formik = useFormikWizard({
    initialValues,
    onSubmit,
    steps,
  });

  const { renderComponent, handlePrev, handleNext, isLastStep, isFirstStep, currentStepIndex, values } = formik;

  useEffect(() => {
    // Update steps based on accountType
    setSteps(generateWizardSteps(values));
  }, [values[updateKey], generateWizardSteps]);

  const onNextHandler = async () => {
    const errors = await formik.validateForm();
    await formik.setTouched(
      Object.keys(errors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    );

    if (isEmpty(errors)) {
      if (onNext) {
        onNext();
      }
      await handleNext();
      setSteps((prevSideMenuSteps) => {
        prevSideMenuSteps[currentStepIndex].isValid = true;
        return [...prevSideMenuSteps];
      });

      scrollbarsRef.current.scrollToTop();
    }
  };

  const onPrevHandler = () => {
    scrollbarsRef.current.scrollToTop();
    handlePrev();
  };

  return (
    <div className={cx('d-flex flex-column', className)}>
      {renderHeaderComponent()}
      <section className='flex-1'>
        <Scrollbars autoHide ref={scrollbarsRef}>
          <section className={cx('d-flex align-items-start justify-content-between px-5 gap-8', 'h-100')}>
            <section className='flex-1 h-100'>{renderComponent()}</section>
          </section>
        </Scrollbars>
      </section>
      <WizardFooter
        className='mt-auto'
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        handlePrev={onPrevHandler}
        currentStepIndex={currentStepIndex}
        stepsValidationState={steps}
        handleNext={onNextHandler}
        nextButtonText={isLastStep ? submitButtonText || 'save' : 'next step'}
      />
    </div>
  );
};

export default PhrWizardWithoutSideMenu;
