import React from 'react';

import IconButton from './IconButton';

const DeleteModal = ({ onClose, onDelete }) => (
  <div>
    <div>
      <div role='document'>
        <div className='py-4 px-5'>
          <div className='border-btm pt-0 px-0 d-flex justify-content-between pb-3'>
            <h5 className='modal-title fw-bold' id='exampleModalLabel'>
              Delete
            </h5>
            <IconButton icon='icon-cross-x' onClick={onClose} />
          </div>
          <div className='px-0 mt-3'>Are you sure to delete selected translation?</div>
          <div className='d-flex justify-content-end py-0 mt-5'>
            <button type='button' className='btn btn-secondary me-4' onClick={onClose}>
              Close
            </button>
            <button type='button' className='btn btn-primary text-white' onClick={onDelete}>
              Delete Translation
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DeleteModal;
