/* eslint-disable react/jsx-indent-props */
import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'react-modal';

import DetailsMobile from './DetailsMobile';
import MobileTableRow from './MobileTableRow';
import MobileTableRowRate from './MobileTableRowRate';
import FeedbacksApi from '../../api/FeedbacksApi';
import modalStyles from '../../components/modalStyles';
import NavTab from '../../components/NavTab';
import Search from '../../components/Search';
import TableSkeleton from '../../components/skeletons/TableSkeleton';
import TableHead from '../../components/table/TableHead';
import EditFeedback from '../components/EditFeedback';
import styles from '../Feedback.module.scss';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '0';

const editModalStyle = _.cloneDeep(modalStyles);
editModalStyle.content.width = '480px';
editModalStyle.content.padding = '0';
editModalStyle.content.overflow = 'visible';

const filterMap = {
  issue: 'issue',
  rate: 'rate',
};

const MobilePage = () => {
  const [activeList, setActiveList] = useState('issue');
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(undefined);
  const [editModal, setEditModal] = useState(false);
  const [editFeedback, setEditFeedback] = useState(undefined);

  const selectList = (list) => {
    setActiveList(list);
  };

  const headerCells = [
    { title: 'Client', sortable: true, sortValue: 'tenant' },
    { title: 'Platform' },
    { title: 'System Version' },
    { title: 'Model' },
    { title: 'App Version' },
    { title: 'Creation Date', sortable: true, sortValue: 'createdAt' },
  ];

  if (filterMap[activeList] === 'issue') {
    headerCells.push({ title: 'Status' });
  }

  // **** Clients **** //

  const selectFeedback = (feedback) => {
    setSelectedFeedback(feedback);
    setDetailsModal(true);
  };

  const getFeedbacks = () => {
    setLoading(true);
    FeedbacksApi.getFeedbacks(filterMap[activeList])
      .then((res) => {
        setFeedbacks(res.data.items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onEditFeedback = (feedback) => {
    setEditModal(true);
    setEditFeedback(feedback);
  };

  const changeFeedbackStatus = (id, data) =>
    FeedbacksApi.editFeedbackStatus('issue', id, data).then(() => {
      getFeedbacks();
    });

  const onIssue = (feedback) => {
    setEditFeedback(feedback);
  };

  const onRate = (feedback) => {
    setEditFeedback(feedback);
  };

  // const closeModalDemo = () => {
  //   onClose(false);
  // };

  useEffect(() => {
    getFeedbacks();
  }, [activeList]);

  const onSort = (option, isAsc) => {
    const dir = isAsc ? 'asc' : 'desc';
    let state = [...filteredFeedback];
    state = _.orderBy(state, [option], [dir]);
    setFilteredFeedback(state);
  };

  useEffect(() => {
    if (searchValue) {
      let state = [...feedbacks];
      state = state.filter((data) => {
        const checkResponsible = data.tenant.toLowerCase().includes(searchValue.toLowerCase());
        const checkName = data.tenant.toLowerCase().includes(searchValue.toLowerCase());
        return checkName || checkResponsible;
      });
      setFilteredFeedback(state);
    } else {
      setFilteredFeedback(feedbacks);
    }
  }, [searchValue, feedbacks]);

  // **** Clients end **** //
  return (
    <div>
      <div className='d-flex justify-content-between my-3 mx-5'>
        <div className='d-flex align-items-center'>
          <NavTab text='issue' active={activeList === 'issue'} onClick={selectList} />
          <NavTab text='rate' active={activeList === 'rate'} onClick={selectList} />
        </div>
        <Search
          onChange={(e) => {
            setSearchValue(e.target.value.replace(/^\s+/g, ''));
          }}
          placeholder='Search client or responsible'
          value={searchValue}
          onClear={() => {
            setSearchValue('');
          }}
        />
      </div>
      {filteredFeedback.length === 0 && !loading ? (
        <div className='pt-8 m-auto'>
          <div className={cx(styles.emptyList, 'm-auto')}>
            <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
              <i className={cx(styles.icon, 'icon-housing')} />
            </div>
            <p className='mt-4 text-center text-capitalize'>{`No ${activeList} feedbacks`}</p>
          </div>
        </div>
      ) : (
        <Scrollbars
          className='scroll-bar'
          autoHide
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
          style={{ height: 'calc(100vh - 260px)' }}
        >
          <div className='px-5 pb-7 mb-7'>
            <table className='w-100 client-list'>
              <TableHead headerCells={headerCells} onSort={onSort} />
              <tbody>
                {filteredFeedback.length > 0 &&
                  !loading &&
                  filteredFeedback.map((item) =>
                    filterMap[activeList] === 'issue' ? (
                      <MobileTableRow
                        key={item.id}
                        data={item}
                        onIssue={() => {
                          onIssue(item);
                        }}
                        onRate={() => {
                          onRate(item);
                        }}
                        onSelect={() => {
                          selectFeedback(item);
                        }}
                        onEdit={() => {
                          onEditFeedback(item);
                        }}
                      />
                    ) : (
                      <MobileTableRowRate
                        key={item.id}
                        data={item}
                        onIssue={() => {
                          onIssue(item);
                        }}
                        onRate={() => {
                          onRate(item);
                        }}
                        onSelect={() => {
                          selectFeedback(item);
                        }}
                        onEdit={() => {
                          onEditFeedback(item);
                        }}
                      />
                    )
                  )}
                {loading && (
                  <tr className='w-100 client-list'>
                    <td colSpan={filterMap[activeList] === 'issue' ? '8' : '7'}>
                      <TableSkeleton lineHight={45} mb='mb-2' num={filterMap[activeList] === 'issue' ? 8 : 7} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
      )}
      <Modal
        isOpen={detailsModal}
        contentLabel='Issue details'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setDetailsModal(false);
        }}
      >
        <DetailsMobile
          id={selectedFeedback?.id}
          onClose={() => {
            setDetailsModal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={editModal}
        contentLabel='Edit Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={editModalStyle}
        onRequestClose={() => {
          setEditModal(false);
        }}
      >
        <EditFeedback
          editData={{
            status: editFeedback?.status,
            id: editFeedback?.id,
          }}
          onClose={() => {
            setEditModal(false);
          }}
          onSave={changeFeedbackStatus}
        />
      </Modal>
    </div>
  );
};

export default MobilePage;
