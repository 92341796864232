import { useGetCustomerAccounts, useQueryGetCustomerById } from '../hooks/useCustomers';
import PageHeader from 'components/PageHeader/PageHeader';
import RoundedButton from 'components/RoundedButton/RoundedButton';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import snakeCase from 'lodash/snakeCase';
import useDebounce from 'Shared/useDebounce';
import IconButton from 'components/IconButton';
import Search from 'components/Search';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import SearchFilterMenu from 'components/searchFilterMenu/SearchFilterMenu';
import SideTransitionContainer from 'components/searchFilterMenu/SideTransitionContainer';
import CustomerAccountsTable from './CustomerAccountsTable/CustomerAccountsTable';
import Header from './Header/Header';
import AddNewAccountModal from '../Modal/AddNewAccountModal/AddNewAccountModal';
import CardsSkeleton from 'components/skeletons/CardsSkeleton';
import { initialfilterMapArray } from 'Customers/constants';

const CustomerAccounts = () => {
  const isAsc = useRef(false);

  const { customerID } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [filterMenu, setFilterMenu] = useState(false);
  const [filterMapArray, setFilterMapArray] = useState(initialfilterMapArray);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [addAcountModal, setAddAccountModal] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  const [params, setParams] = useState({
    status: 'active',
    currentPage: 1,
    perPage: 5,
    search: '',
    sort: '',
  });

  const { isLoading: isLoadingAccounts, data } = useGetCustomerAccounts(customerID, {
    ...params,
    status: snakeCase(params.status),
  });

  const { isLoading: isLoadingCustomer, data: customerData } = useQueryGetCustomerById(customerID);

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, currentPage: 1, search: debouncedSearch }));
  }, [debouncedSearch]);

  const accountsData = data?.data?.items || [];

  const handleClickBack = () => {
    navigate('/customers');
  };

  const onSort = (option) => {
    isAsc.current = !isAsc.current;
    const dir = isAsc.current ? '+' : '-';
    setParams((prevState) => ({ ...prevState, currentPage: 1, sort: `${dir}${option}` }));
  };

  const updatePaginationParams = ({ currentPage, perPage }) => {
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));
  };

  const closeFilterMenu = () => {
    setFilterMenu(false);
  };

  const openFilterMenu = () => {
    setFilterMenu(true);
  };

  const onSelectField = (value, field, id, groupTitle, label) => {
    setSelectedFilter((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      const filterIndex = updatedFilters.findIndex((f) => f.id === id);

      if (filterIndex >= 0) {
        updatedFilters[filterIndex].selected = value;
      } else {
        updatedFilters.push({ field, id, label, selected: value, value: [_.toLower(id)] });
      }

      return updatedFilters;
    });

    const stateArray = [...filterMapArray];
    stateArray.every((el) => {
      if (el.title === groupTitle) {
        el.options.every((elem) => {
          if (`${elem.id}` === `${id}`) {
            elem.selected = value;

            return false;
          }

          return true;
        });
      }

      return true;
    });
    setFilterMapArray(stateArray);
  };

  const onApplyFilter = (filters) => {
    setFilterMenu(false);
    const accountTypeFilters = filters
      .filter((f) => f.field === 'accountType' && f.selected)
      .map((f) => {
        if (f.label.toLowerCase() === 'live site') {
          return 'LIVE_INSTANCE';
        }
        return f.label.toUpperCase().replace(' ', '_');
      });

    const accountStatusFilters = filters
      .filter((f) => f.field === 'accountStatus' && f.selected)
      .map((f) => f.label.toUpperCase().replace(' ', '_'));

    setParams((prevParams) => {
      const newParams = { ...prevParams };

      if (accountTypeFilters.length > 0) {
        newParams.accountType = accountTypeFilters.join(',');
      } else {
        delete newParams.accountType;
      }

      if (accountStatusFilters.length > 0) {
        newParams.accountStatus = accountStatusFilters.join(',');
      } else {
        delete newParams.accountStatus;
      }

      return newParams;
    });
    // Clear ALL
    if (filters.length === 0) {
      const stateArray = [...filterMapArray];

      stateArray.forEach((el) => {
        el.options.forEach((elem) => {
          elem.selected = false;
        });
      });

      setFilterMapArray(stateArray);
      setSelectedFilter([]);
    }
  };

  return (
    <div className='mt-3 mx-3 panel p-0' style={{ minHeight: '100vh' }}>
      <PageHeader
        isShowBackButton
        onClickGoBack={handleClickBack}
        title={customerData?.data?.companyName || ''}
        renderSideActionComponent={() => (
          <RoundedButton
            isDisabled={isLoadingCustomer}
            icon='icon-plus'
            text='Add Account'
            color='primary'
            size='sm'
            onClick={() => setAddAccountModal(true)}
          />
        )}
      />
      <div className='px-5'>
        {isLoadingCustomer && <CardsSkeleton col={12} height={289} num={1} />}
        {!isLoadingCustomer && <Header details={customerData.data} />}
      </div>
      <h6 className='font-weight-bold px-5 my-2'>
        Accounts <span className='text-gray-500'>({accountsData.length})</span>
      </h6>
      <div className='d-flex mb-2 mt-2 flex-row align-items-center justify-content-between px-5'>
        <div className='col-4 p-2'>
          <Search
            className='p-0'
            onChange={(e) => {
              setSearch(e.target.value.replace(/^\s+/g, ''));
            }}
            placeholder='Search customer'
            value={search}
            onClear={() => {
              setSearch('');
            }}
          />
        </div>
        <div>
          <SideTransitionContainer isIn={filterMenu} onClose={closeFilterMenu}>
            <SearchFilterMenu
              filterMapArray={filterMapArray}
              selectedFilter={selectedFilter}
              onApplyFilter={onApplyFilter}
              onSelectField={onSelectField}
              onClose={closeFilterMenu}
            />
          </SideTransitionContainer>
          <IconButton icon='icon-filter' onClick={openFilterMenu} size='md' />
        </div>
      </div>
      <div style={{ height: 'calc(100vh - 410px)' }} className='mx-5 d-flex justify-content-start flex-column gap-2'>
        <CustomerAccountsTable onSort={onSort} isLoading={isLoadingAccounts} accounts={accountsData} />
        {data?.data?.pagination?.total > 0 && (
          <PHRPagination onPaginationAction={updatePaginationParams} pagination={data?.data?.pagination} />
        )}
      </div>
      {!isLoadingCustomer && (
        <AddNewAccountModal
          isOpen={addAcountModal}
          id={customerID}
          country={customerData?.data.country}
          onClose={() => {
            setAddAccountModal(false);
          }}
        />
      )}
    </div>
  );
};

export default CustomerAccounts;
