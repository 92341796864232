import Menu from 'components/Menu/Menu';
import React, { useState } from 'react';
import DetailsCustomer from 'Customers/CustomersPage/components/DetailsCustomer/DetailsCustomer';
import DetailsModal from 'Customers/components/detailsModal/DetailsModal';
import Modal from 'react-modal';
import modalStyles from 'components/modalStyles';
import cloneDeep from 'lodash/cloneDeep';
import ClientContext from 'Customers/components/detailsModal/ClientContext';
import { getAccountType, getAccountOptions } from '../../utility';
import {
  useMutationSuspendCustomer,
  useMutationActivateCustomer,
  useMutationArchiveCustomer,
  useMutationSyncCustomerSubscription,
  useMutationRetryFailedCustomer,
  useMutationCancelTenantSubscription,
} from 'Customers/hooks/useCustomers';
import { useQueryClient } from '@tanstack/react-query';
import StatusChip from 'Customers/components/StatusChip/StatusChip';
import AddAdminModal from '../AddAdminModal/AddAdminModal';

const addModalStyle = cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.padding = '0';

const CustomerAccountsTableRow = ({ accountType, subdomain, fullDomain, status, id, name }) => {
  const queryClient = useQueryClient();
  const account = { accountType, subdomain, status, id };
  const [detailsModal, setDetailsModal] = useState(false);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [detailsCustomer, setDetailsCustomer] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const { mutate: suspendCustomer } = useMutationSuspendCustomer();
  const { mutate: archiveCustomer } = useMutationArchiveCustomer();
  const { mutate: syncSubscription } = useMutationSyncCustomerSubscription();
  const { mutate: activateCustomer } = useMutationActivateCustomer();
  const { mutate: retryFailedCustomer } = useMutationRetryFailedCustomer();
  const { mutate: cancelSubscription } = useMutationCancelTenantSubscription();

  const accountOptions = getAccountOptions(status, accountType);

  const openDetailsHandler = (item) => {
    setSelected(item);
  };

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(['customersAccounts', id]);
  };

  const handleShowDetails = () => {
    setSelected(account);

    const shouldShowDetails =
      accountType !== 'LIVE_INSTANCE' ||
      (status !== 'ACTIVE' && status !== 'PENDING_SUSPENSION' && status !== 'SUSPENDED' && status !== 'CANCELLED');
    if (status !== 'PENDING') {
      setDetailsCustomer(shouldShowDetails);
      setDetailsModal(!shouldShowDetails);
    }
  };

  const handleAction = (action) => {
    switch (action) {
      case 'Suspend':
        suspendCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'SyncSubscription':
        syncSubscription(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'AddAdmin':
        setAddAdminModal(true);
        break;
      case 'Activate':
        activateCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Retry':
        retryFailedCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Archive':
        archiveCustomer(id, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      case 'Delete':
        cancelSubscription(name, {
          onSuccess: () => {
            handleSuccess();
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <ClientContext.Provider value={{ selected, openDetailsHandler }}>
      <tr onClick={handleShowDetails}>
        <td className='d-flex align-items-center py-3 px-3'>{getAccountType.get(accountType)}</td>
        <td>
          <span>
            {status === 'FAILED' ? (
              <i className='icon-not-verified-fill text-red me-2' />
            ) : (
              <i className='icon-verified1-fill text-green me-2' />
            )}
            {fullDomain}
          </span>
        </td>
        <td>
          <StatusChip status={status} />
        </td>
        <td>
          {status !== 'PENDING' && accountOptions.length > 0 && (
            <Menu data={accountOptions} onAction={(action) => handleAction(action)} />
          )}
        </td>
      </tr>
      <Modal
        isOpen={detailsModal}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setDetailsModal(false);
        }}
      >
        <DetailsModal
          customer={selected || {}}
          onClose={() => {
            setDetailsModal(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={detailsCustomer}
        contentLabel='Add Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setDetailsCustomer(false);
        }}
      >
        <DetailsCustomer
          customer={selected || {}}
          onClose={() => {
            setDetailsCustomer(false);
          }}
        />
      </Modal>
      <AddAdminModal
        isOpen={addAdminModal}
        id={id}
        onClose={() => {
          setAddAdminModal(false);
        }}
      />
    </ClientContext.Provider>
  );
};

export default CustomerAccountsTableRow;
