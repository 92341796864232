import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './EditMenu.module.scss';

const link = cx(styles.link, 'd-flex', 'align-items-center', 'px-3', 'py-2');

const EditMenu = ({ onEdit, onDelete, isPrivate, onPublish, onPreview, announcementId }) => (
  <ul
    className={cx(styles.editMenu, 'radius-4', 'bg-white', 'py-2', 'px-0')}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <li className={styles.listItem} onClick={onEdit}>
      <div className={link}>
        <i className='icon-pencil-create me-2' />
        Edit
      </div>
    </li>
    <li className={styles.listItem} onClick={onPreview}>
      <div className={link}>
        <i className='icon-glasses me-2' />
        Preview
      </div>
    </li>
    <li className={styles.listItem} onClick={onDelete}>
      <div className={link}>
        <i className='icon-trash me-2' />
        Delete
      </div>
    </li>
    {isPrivate && (
      <li
        className={styles.listItem}
        onClick={() => {
          onPublish(announcementId);
        }}
      >
        <div className={link}>
          <i className='icon-cloud-upload me-2' />
          Publish
        </div>
      </li>
    )}
  </ul>
);

EditMenu.propTypes = {
  onPublish: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  announcementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditMenu.defaultProps = {
  announcementId: undefined,
};

export default EditMenu;
