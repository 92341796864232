import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './DetailsMobile.module.scss';
import FeedbacksApi from '../../api/FeedbacksApi';
import IconButton from '../../components/IconButton';
import SpinnerRing from '../../components/SpinnerRing';

const filterMap = {
  issue: 'issue',
  rate: 'rate',
};

const DetailsMobile = ({ id, onClose }) => {
  const [activeList] = useState('issue');
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    FeedbacksApi.feedbackDetails(filterMap[activeList], id)
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className='pt-4 ps-5'>
      {loading && <SpinnerRing />}
      <div className='d-flex justify-content-between border-btm pt-0 px-0 pb-3 me-5 ms-1'>
        <a href={details.domain} target='_blank' rel='noopener noreferrer' className={styles.link}>
          <h5 className='ps-0 pe-3 modal-title fw-bold'>{details.tenant}</h5>
        </a>
        <div className='p-0 d-flex justify-content-end'>
          <IconButton icon='icon-cross-x' onClick={onClose} />
        </div>
      </div>
      <div>
        <Scrollbars
          className='scroll-bar'
          autoHide
          renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
          style={{ height: '580px' }}
        >
          <div>
            <div className='pe-5 pt-5'>
              <p className='fw-bold mt-1 mb-3'>Created By</p>
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-6')}>
                  <p className='label-title'>Reporter</p>
                  <p className='mb-3 text-steal'>{details.reporter}</p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-6')}>
                  <p htmlFor='workEmail' className='label-title'>
                    Contact email
                  </p>
                  <p className='mb-3 text-steal'>{details.reporterContactEmail}</p>
                </div>
              </div>
              <div className={styles.line} />
              <p className='fw-bold mt-5 mb-3'>Feedback</p>
              <div className={cx(styles.inputHeight, 'form-group col-12')}>
                <div className='row'>
                  <p className='mb-3 text-steal'>{details.description}</p>
                </div>
              </div>
              <div className={styles.line} />
              <p className='fw-bold mt-5 mb-3'>Details</p>
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>App Version</p>
                  <p className='mb-3 text-steal'>
                    <p className='mb-3 text-steal'>{details.appVersion}</p>
                  </p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>Platform</p>
                  <p className='mb-3 text-steal'>{details.platform}</p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>System Version</p>
                  <p className='mb-3 text-steal'>
                    <p className='mb-3 text-steal'>{details.systemVersion}</p>
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>Device</p>
                  <p className='mb-3 text-steal'>
                    <p className='mb-3 text-steal'>{details.device}</p>
                  </p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>Model</p>
                  <p className='mb-3 text-steal'>
                    <p className='mb-3 text-steal'>{details.model}</p>
                  </p>
                </div>
                <div className={cx(styles.inputHeight, 'form-group col-4')}>
                  <p className='label-title'>Display</p>
                  <p className='mb-3 text-steal'>
                    <p className='mb-3 text-steal'>{details.display}</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
        <div className='d-flex justify-content-end border-t py-4 mt-1 me-5'>
          <button className='btn btn-primary text-white' onClick={onClose} type='button'>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

DetailsMobile.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

DetailsMobile.defaultProps = {
  id: undefined,
};

export default DetailsMobile;
