import React, { useState } from 'react';

import cx from 'classnames';

import styles from './Feedback.module.scss';
import MobilePage from './Mobile/MobilePage';
import WebPage from './Web/WebPage';
import SelectMenu from '../components/SelectMenu';

const Feedback = () => {
  const [activePage, setActivePage] = useState('mobile');
  const [addMobileModal, setAddMobileModal] = useState(false);
  const [addWebModal, setAddWebModal] = useState(false);
  const [typeMenu, setTypeMenu] = useState(false);

  const selectPage = (page) => {
    setActivePage(page);
  };

  const selectFeedbackType = (type) => {
    if (type === 'mobile') {
      setAddMobileModal(true);
    } else {
      setAddWebModal(true);
    }
    setActivePage(type);
    setTypeMenu(false);
  };

  return (
    <div className='m-3'>
      <div className={cx(styles.wrapper, 'panel adds-page p-0')}>
        <div className={cx(styles.header, 'row adds-page-header px-5 py-4')}>
          <div className='col-4'>
            <h2>Feedback</h2>
            <p className='tab-subtitle'>List of feedbacks from mobile and web platforms</p>
          </div>
          <div className='d-flex col-4 align-items-center justify-content-center'>
            <button
              onClick={() => {
                selectPage('mobile');
              }}
              type='button'
              className={cx(styles.pageTab, 'py-1 px-2a me-3', activePage === 'mobile' && styles.active)}
            >
              mobile
            </button>
            <button
              onClick={() => {
                selectPage('web');
              }}
              type='button'
              className={cx(styles.pageTab, 'py-1 px-2a me-3', activePage === 'web' && styles.active)}
            >
              web
            </button>
          </div>
          <div className='col-4 d-flex align-items-center'>
            {typeMenu && (
              <SelectMenu onClose={() => setTypeMenu(false)} items={['mobile', 'web']} onSelect={selectFeedbackType} />
            )}
          </div>
        </div>
        <div className={cx(styles.content, 'bg-white')}>
          <div className='w-100 position-relative h-100'>
            <div>
              {activePage === 'web' ? (
                <WebPage
                  addWebModal={addWebModal}
                  onClose={() => {
                    setAddWebModal(false);
                  }}
                />
              ) : (
                <MobilePage
                  addMobileModal={addMobileModal}
                  onClose={() => {
                    setAddMobileModal(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
