import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars-2';

import styles from './AnnouncementModal.module.scss';
import IconButton from '../../components/IconButton';
import MultipleSelectField from '../../components/MultipleSelectFields';
import SpinnerRing from '../../components/SpinnerRing';
import SuccessCheckmark from '../../components/SuccessCheckmark';
import permissionGroupsUtility from '../../utility/permissionGroupsUtility';

const permissionGroups = permissionGroupsUtility.permissionGroupOptions();

const permissionGroupOptions = () => {
  const options = [{ id: 'ALL', name: 'All' }];

  Object.keys(permissionGroups).forEach((id) => {
    options.push({ id, name: permissionGroups[id] });
  });

  return options;
};

const AnnouncementModal = ({ onClose, onSave, editData, saving, saved, errorMessageOnSave }) => {
  const [announcementsNew, setAnnouncementsNew] = useState([]);
  const [announcementsImprovements, setAnnouncementsImprovements] = useState([]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Title is required';
    }

    if (!values.shared || values.shared.length === 0) {
      errors.shared = 'Shared is required';
    }

    if (!values.visibility) {
      errors.visibility = 'Visibility is required';
    }

    if (!values.platform) {
      errors.platform = 'Platform is required';
    }

    if (!values.releaseDate) {
      errors.releaseDate = 'Realse date is required';
    }

    if (!values.startDate) {
      errors.startDate = 'Start date is required';
    }

    if (!values.endDate) {
      errors.endDate = 'End date is required';
    }

    if (announcementsImprovements.length === 0 && announcementsNew.length === 0) {
      errors.announcementItems = 'Announcements is required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      title: editData?.title || '',
      titleArabic: editData?.titleArabic || '',
      videoUrl: editData?.videoUrl || '',
      documentUrl: editData?.documentUrl || '',
      shared: [],
      releaseDate: editData?.releaseDate ? moment(editData.releaseDate).format('YYYY-MM-DD') : '',
      startDate: editData?.startDate ? moment(editData.startDate).format('YYYY-MM-DD') : '',
      endDate: editData?.endDate ? moment(editData.endDate).format('YYYY-MM-DD') : '',
      visibility: editData?.visibility || 'PRIVATE',
      platform: editData?.platform || 'WEB',
      announcementItems: [],
    },
    validate,
    onSubmit: (values) => {
      const sentData = { ...values };
      const arrNew = announcementsNew.filter((el) => el.title !== '');
      const arrImp = announcementsImprovements.filter((el) => el.title !== '');
      sentData.announcementItems = [...arrNew, ...arrImp];

      sentData.releaseDate = moment(values.releaseDate).format('YYYY-MM-DD');
      sentData.startDate = moment(values.startDate).format('YYYY-MM-DD');
      sentData.endDate = moment(values.endDate).format('YYYY-MM-DD');

      setAnnouncementsNew(arrNew);
      setAnnouncementsImprovements(arrImp);
      if (sentData.announcementItems.length === 0) {
        formik.setFieldError('announcementItems', 'Announcements is required');
        return;
      }

      sentData.shared = [];
      values.shared.forEach((el) => {
        sentData.shared.push(el.value);
      });

      if (sentData.shared.includes('ALL')) {
        sentData.shared = permissionGroupsUtility.permissionGroups();
      }

      onSave(!!editData, sentData);
    },
  });

  const setData = () => {
    if (editData && editData.featureItems && editData.featureItems.length > 0) {
      const arrNew = [];

      editData.featureItems.forEach((el) => {
        arrNew.push({
          title: el.title,
          titleArabic: el.titleArabic,
          category: el.category,
          videoUrl: el.videoUrl,
          documentUrl: el.documentUrl,
        });
      });

      setAnnouncementsNew(arrNew);
    }

    if (editData && editData?.improvementItems && editData?.improvementItems?.length > 0) {
      const arrImp = [];
      editData.improvementItems.forEach((el) => {
        arrImp.push({
          title: el.title,
          titleArabic: el.titleArabic,
          category: el.category,
          videoUrl: el.videoUrl,
          documentUrl: el.documentUrl,
        });
      });

      setAnnouncementsImprovements(arrImp);
    }

    if (editData && editData.shared && editData.shared.length > 0) {
      const arrShared = [];
      editData.shared.forEach((el) => {
        arrShared.push({
          value: el,
          label: permissionGroups[el],
        });
      });

      formik.setFieldValue('shared', arrShared);
    }
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className={styles.overfowX}>
      {saving && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <div>
        <div className='py-4 ps-5 position-relative'>
          <div className='border-btm d-flex justify-content-between pt-0 px-0 pb-3 me-5'>
            <h5 className='modal-title fw-bold-bold'>{editData ? 'Edit Announcement' : 'Add Announcement'}</h5>
            <IconButton icon='icon-cross-x' onClick={onClose} />
          </div>
          <div className='px-0 pt-4'>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Scrollbars
                className='scroll-bar'
                autoHide
                renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
                style={{ height: '72vh' }}
              >
                <div className='pe-5'>
                  <div className='row pb-7'>
                    <div className='form-group input-hight-acm col-6 mb-3'>
                      <label htmlFor='title' className='label-title'>
                        Title*
                      </label>
                      <input
                        type='text'
                        name='title'
                        id='title'
                        placeholder='Title'
                        required
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control newClientInputs ${
                          formik.errors.title && formik.touched.title ? 'is-invalid' : ''
                        }`}
                      />
                      <div className='invalid-feedback'>{formik.errors.title}</div>
                    </div>
                    <div className='form-group input-hight-acm col-6 mb-3'>
                      <label htmlFor='titleArabic' className='label-title'>
                        Title Arabic
                      </label>
                      <input
                        type='text'
                        dir='rtl'
                        name='titleArabic'
                        id='titleArabic'
                        placeholder='Title Arabic'
                        value={formik.values.titleArabic}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='form-control newClientInputs'
                      />
                    </div>

                    <div className='form-group input-hight-acm col-12 mb-5'>
                      <label htmlFor='videoUrl' className='label-title'>
                        Video URL
                      </label>
                      <input
                        type='text'
                        name='videoUrl'
                        id='videoUrl'
                        placeholder='https://video.url'
                        value={formik.values.videoUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='form-control newClientInputs'
                      />
                    </div>
                    <div className='form-group input-hight-acm col-12 mb-5'>
                      <label htmlFor='documentUrl' className='label-title'>
                        Document URL
                      </label>
                      <input
                        type='text'
                        name='documentUrl'
                        id='documentUrl'
                        placeholder='https://document.url'
                        value={formik.values.documentUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='form-control newClientInputs'
                      />
                    </div>

                    <p className='fw-bold mb-2 px-3 w-100'>Who can see the notifications</p>
                    <div className='form-group input-hight-acm col-4 mb-5'>
                      <MultipleSelectField
                        isFormik={false}
                        name='shared'
                        label='Shared with'
                        isRequired
                        error={formik.touched.shared && formik.errors.shared}
                        value={formik.values.shared}
                        options={permissionGroupOptions()}
                        change={formik.setFieldValue}
                        blur={formik.handleBlur}
                      />
                    </div>
                    <div className='form-group input-hight-acm col-4 mb-5'>
                      <label htmlFor='visibility' className='label-title'>
                        Visibility*
                      </label>
                      <select
                        name='visibility'
                        required
                        onChange={formik.handleChange}
                        value={formik.values.visibility}
                        className={`form-control newClientInputs ${
                          formik.errors.visibility && formik.touched.visibility ? 'is-invalid' : ''
                        }`}
                      >
                        <option value='PRIVATE'>Private</option>
                        <option value='PUBLIC'>Public</option>
                      </select>
                      <div className='invalid-feedback'>{formik.errors.visibility}</div>
                    </div>
                    <div className='form-group input-hight-acm col-4 mb-5'>
                      <label htmlFor='platform' className='label-title'>
                        Platform*
                      </label>
                      <select
                        name='platform'
                        required
                        onChange={formik.handleChange}
                        value={formik.values.platform}
                        className={`form-control newClientInputs ${
                          formik.errors.platform && formik.touched.platform ? 'is-invalid' : ''
                        }`}
                      >
                        <option value='WEB'>Web</option>
                        <option value='MOB'>Mobile</option>
                      </select>
                      <div className='invalid-feedback'>{formik.errors.platform}</div>
                    </div>
                    <p className='fw-bold mb-2 px-3 w-100'>When will be seen</p>
                    <div className='form-group input-hight-acm col-4 mb-3'>
                      <label htmlFor='releaseDate' className='label-title'>
                        Release Date*
                      </label>
                      <input
                        type='date'
                        name='releaseDate'
                        id='releaseDate'
                        placeholder='Select'
                        required
                        value={formik.values.releaseDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control newClientInputs ${
                          formik.errors.releaseDate && formik.touched.releaseDate ? 'is-invalid' : ''
                        }`}
                      />
                      <div className='invalid-feedback'>{formik.errors.releaseDate}</div>
                    </div>
                    <div className='form-group input-hight-acm col-4 mb-5'>
                      <label htmlFor='startDate' className='label-title'>
                        Start Date*
                      </label>
                      <input
                        type='date'
                        name='startDate'
                        id='startDate'
                        placeholder='Select'
                        required
                        min={formik.values.releaseDate}
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control newClientInputs ${
                          formik.errors.startDate && formik.touched.startDate ? 'is-invalid' : ''
                        }`}
                      />
                      <div className='invalid-feedback'>{formik.errors.startDate}</div>
                    </div>
                    <div className='form-group input-hight-acm col-4 mb-5'>
                      <label htmlFor='endDate' className='label-title'>
                        End Date*
                      </label>
                      <input
                        type='date'
                        name='endDate'
                        id='endDate'
                        placeholder='Select'
                        required
                        min={formik.values.startDate}
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control newClientInputs ${
                          formik.errors.endDate && formik.touched.endDate ? 'is-invalid' : ''
                        }`}
                      />
                      <div className='invalid-feedback'>{formik.errors.endDate}</div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center px-3 w-100'>
                      <p className='fw-bold mb-2'>What is New</p>

                      <IconButton
                        icon='icon-circle-plus'
                        onClick={() => {
                          const arr = [...announcementsNew];
                          arr.push({
                            id: new Date().getTime(),
                            title: '',
                            titleArabic: '',
                            category: 'FEATURE',
                          });
                          setAnnouncementsNew(arr);
                        }}
                      />
                    </div>
                    {announcementsNew?.map((el, index) => (
                      <div
                        key={el.id}
                        className='form-group input-hight-acm col-12 mb-3 d-flex align-items-start border-btm'
                      >
                        <div className='row'>
                          <div className='form-group input-hight-acm col-6 mb-0'>
                            <label htmlFor={`new-${index}`} className='label-title'>
                              Title*
                            </label>
                            <textarea
                              id={`new-${index}`}
                              name={`new-${index}`}
                              placeholder='Maximum 250 characters'
                              maxLength={250}
                              value={el.title || ''}
                              onChange={(e) => {
                                const arr = [...announcementsNew];
                                arr[index].title = e.target.value;
                                setAnnouncementsNew(arr);
                              }}
                              onBlur={formik.handleBlur}
                              className='form-control'
                              rows={2}
                            />
                            {el.title?.length > 249 && <div className='error'>No more characters (maximum 250).</div>}
                          </div>

                          <div className='form-group input-hight-acm col-6 mb-0'>
                            <label htmlFor={`imp-${index}`} className='label-title'>
                              Title Arabic
                            </label>
                            <textarea
                              className='form-control'
                              dir='rtl'
                              id={`imp-${index}`}
                              placeholder='Maximum 250 characters'
                              maxLength={250}
                              value={el.titleArabic || ''}
                              onChange={(e) => {
                                const arr = [...announcementsNew];
                                arr[index].titleArabic = e.target.value;
                                setAnnouncementsNew(arr);
                              }}
                              rows={2}
                            />
                            {el.titleArabic?.length > 249 && (
                              <div className='error'>No more characters (maximum 250).</div>
                            )}
                          </div>
                          <div className='form-group input-hight-acm col-12 mt-5 mb-7'>
                            <label htmlFor={`url-${index}`} className='label-title'>
                              video url
                            </label>
                            <input
                              type='text'
                              id={`url-${index}`}
                              value={el.videoUrl || ''}
                              placeholder='https://video.url'
                              onChange={(e) => {
                                const arr = [...announcementsNew];
                                arr[index].videoUrl = e.target.value;
                                setAnnouncementsNew(arr);
                              }}
                              className='form-control'
                              rows={2}
                            />
                          </div>
                          <div className='form-group input-hight-acm col-12 mt-5 mb-7'>
                            <label htmlFor={`url-new-doc-${index}`} className='label-title'>
                              document url
                            </label>
                            <input
                              type='text'
                              id={`url-new-doc-${index}`}
                              value={el.documentUrl || ''}
                              placeholder='https://document.url'
                              onChange={(e) => {
                                const arr = [...announcementsNew];
                                arr[index].documentUrl = e.target.value;
                                setAnnouncementsNew(arr);
                              }}
                              className='form-control'
                              rows={2}
                            />
                          </div>
                        </div>

                        <IconButton
                          icon='icon-trash'
                          className={styles.deleteBtn}
                          onClick={() => {
                            const arr = [...announcementsNew];
                            arr.splice(index, 1);
                            setAnnouncementsNew(arr);
                          }}
                        />
                      </div>
                    ))}

                    <div className='d-flex justify-content-between align-items-center px-3 w-100 mt-5'>
                      <p className='fw-bold mb-2'>What is Improved</p>
                      <IconButton
                        icon='icon-circle-plus'
                        onClick={() => {
                          const arr = [...announcementsImprovements];
                          arr.push({
                            id: new Date().getTime(),
                            title: '',
                            titleArabic: '',
                            category: 'IMPROVEMENT',
                          });
                          setAnnouncementsImprovements(arr);
                        }}
                      />
                    </div>
                    {announcementsImprovements?.map((el, index) => (
                      <div
                        key={el.id}
                        className='form-group input-hight-acm col-12 mb-3 d-flex align-items-start border-btm'
                      >
                        <div className='row'>
                          <div className='form-group input-hight-acm col-6 mb-0'>
                            <label htmlFor={`new-${index}`} className='label-title'>
                              Title*
                            </label>
                            <textarea
                              id={`new-${index}`}
                              placeholder='Maximum 250 characters'
                              maxLength={250}
                              value={el.title || ''}
                              onChange={(e) => {
                                const arr = [...announcementsImprovements];
                                arr[index].title = e.target.value;
                                setAnnouncementsImprovements(arr);
                              }}
                              className='form-control'
                              rows={2}
                            />

                            {el.title?.length > 249 && <div className='error'>No more characters (maximum 250).</div>}
                          </div>

                          <div className='form-group input-hight-acm col-6 mb-0'>
                            <label htmlFor={`imp-${index}`} className='label-title'>
                              Title Arabic
                            </label>
                            <textarea
                              dir='rtl'
                              className='form-control'
                              id={`imp-${index}`}
                              placeholder='Maximum 250 characters'
                              maxLength={250}
                              value={el.titleArabic || ''}
                              onChange={(e) => {
                                const arr = [...announcementsImprovements];
                                arr[index].titleArabic = e.target.value;
                                setAnnouncementsImprovements(arr);
                              }}
                              rows={2}
                            />
                            {el.titleArabic?.length > 249 && (
                              <div className='error'>No more characters (maximum 250).</div>
                            )}
                          </div>
                          <div className='form-group input-hight-acm col-12 mt-5 mb-7'>
                            <label htmlFor={`url-new-${index}`} className='label-title'>
                              video url
                            </label>
                            <input
                              type='text'
                              id={`url-new-${index}`}
                              value={el.videoUrl || ''}
                              placeholder='https://video.url'
                              onChange={(e) => {
                                const arr = [...announcementsImprovements];
                                arr[index].videoUrl = e.target.value;
                                setAnnouncementsImprovements(arr);
                              }}
                              className='form-control'
                              rows={2}
                            />
                          </div>
                          <div className='form-group input-hight-acm col-12 mt-5 mb-7'>
                            <label htmlFor={`url-imp-doc-${index}`} className='label-title'>
                              document url
                            </label>
                            <input
                              type='text'
                              id={`url-imp-doc-${index}`}
                              value={el.documentUrl || ''}
                              placeholder='https://document.url'
                              onChange={(e) => {
                                const arr = [...announcementsImprovements];
                                arr[index].documentUrl = e.target.value;
                                setAnnouncementsImprovements(arr);
                              }}
                              className='form-control'
                              rows={2}
                            />
                          </div>
                        </div>

                        <IconButton
                          icon='icon-trash'
                          className={styles.deleteBtn}
                          onClick={() => {
                            const arr = [...announcementsImprovements];
                            arr.splice(index, 1);
                            setAnnouncementsImprovements(arr);
                          }}
                        />
                      </div>
                    ))}

                    <div className='form-group input-hight-acm col-12 my-3'>
                      <input
                        type='hidden'
                        name='announcementItems'
                        id='announcementItems'
                        required
                        value={formik.values.announcementItems}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control newClientInputs ${
                          formik.errors.announcementItems && formik.touched.announcementItems ? 'is-invalid' : ''
                        }`}
                      />
                      <div className='invalid-feedback'>{formik.errors.announcementItems}</div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
              <div className='pt-4 me-5 mt-1 d-flex justify-content-end align-items-center border-t'>
                {errorMessageOnSave && <p className={styles.errorMessage}>{errorMessageOnSave}</p>}

                <button type='button' className='btn btn-secondary me-5' onClick={onClose} disabled={saving}>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary text-white'>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

AnnouncementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  onSave: PropTypes.func,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  errorMessageOnSave: PropTypes.string,
};

AnnouncementModal.defaultProps = {
  onSave: undefined,
  saving: false,
  saved: false,
  editData: null,
  errorMessageOnSave: '',
};

export default AnnouncementModal;
