import React, { useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys, useUpdateUser, useMutationAddUser } from './hooks/useUsers';
import IconButton from '../components/IconButton';

const AddUser = ({ onClose, selectedUser, params }) => {
  const queryClient = useQueryClient();
  const [isSending, setIsSending] = useState(false);
  const { mutate: updateMutate } = useUpdateUser();
  const { mutate: addMutate } = useMutationAddUser();
  const handleEditSuccess = async () => {
    await queryClient.invalidateQueries(queryKeys.users(params), { exact: true });
    setIsSending(false);
    onClose();
  };
  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = 'Full name is required';
    }
    if (!values.username) {
      errors.username = 'Email is required';
    }
    if (!values.permissionGroup) {
      errors.permissionGroup = 'Role is required';
    }
    return errors;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: selectedUser ? selectedUser.fullName : '',
      username: selectedUser ? selectedUser.username : '',
      permissionGroup: selectedUser ? selectedUser.permissionGroup : '',
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      if (selectedUser) {
        updateMutate(selectedUser.id, values, {
          onSuccess: async () => {
            handleEditSuccess();
          },
        });
      } else {
        addMutate(values, {
          onSuccess: async () => {
            handleEditSuccess();
          },
        });
      }
    },
  });
  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
    formik.resetForm();
  };
  // --- render view addUser ---//
  return (
    <div>
      <div>
        <div className='position-relative'>
          <div className='border-btm pt-0 px-0 pb-2 d-flex justify-content-between'>
            <h5 className='fw-bold'>{selectedUser ? 'Edit user' : 'Add user'}</h5>
            <IconButton icon='icon-cross-x' onClick={closeHandler} />
          </div>
          <div className='px-0 pt-4'>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className='form-group input-hight-acm mb-3'>
                <label htmlFor='fullName' className='label-title'>
                  Full name*
                </label>
                <input
                  type='text'
                  name='fullName'
                  id='fullName'
                  placeholder='John Doe'
                  required
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${formik.errors.fullName && formik.touched.fullName ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{formik.errors.fullName}</div>
              </div>
              <div className='form-group input-hight-acm mb-3'>
                <label htmlFor='username' className='label-title'>
                  Email*
                </label>
                <input
                  type='text'
                  name='username'
                  id='username'
                  placeholder='john@doe.com'
                  required
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${formik.errors.username && formik.touched.username ? 'is-invalid' : ''}`}
                />
                <div className='invalid-feedback'>{formik.errors.username}</div>
              </div>
              <div className='form-group input-hight-acm mb-2'>
                <label htmlFor='permissionGroup' className='label-title'>
                  User role*
                </label>
                <select
                  name='permissionGroup'
                  required
                  onChange={formik.handleChange}
                  value={formik.values.permissionGroup}
                  className={`form-control ${
                    formik.errors.permissionGroup && formik.touched.permissionGroup ? 'is-invalid' : ''
                  }`}
                >
                  <option value=''>Choose role</option>
                  <option value='ROLE_TRANSLATIONS_MANAGER'>Translations manager</option>
                  <option value='ROLE_CLIENTS_MANAGER'>Clients manager</option>
                  <option value='ROLE_ADMIN'>Admin</option>
                </select>
                <div className='invalid-feedback'>{formik.errors.permissionGroup}</div>
              </div>
              <div className='py-1 mt-10 d-flex justify-content-end'>
                <button type='button' className='btn btn-secondary me-4' onClick={closeHandler} disabled={isSending}>
                  Close
                </button>
                {isSending ? (
                  <button className='btn btn-primary text-white' type='button' disabled>
                    <span className='spinner-border spinner-border-sm me-4 ms-1' role='status' aria-hidden='true' />
                    Sending...
                  </button>
                ) : (
                  <button type='submit' className='btn btn-primary text-white'>
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
AddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AddUser;