import React from 'react';

const TenantEventLog = ({ data }) => {
  const humanNames = {
    cancel_client_subscription: 'Cancel subscription',
    reactivate_client_event: 'Reactivate subscription',
    resume_client_event: 'Resume subscription',
    subscribe_demo_client: 'Subscribe demo',
    suspend_client_event: 'Suspend client',
    upgrade_client_subscription: 'Upgrade client',
    tenant_creation_requested: 'Create tenant',
    tenant_create_database_event: 'Create database',
    tenant_create_first_user_event: 'Create first user',
    tenant_create_subscription: 'Create subscription',
    archive_client_requested_event: 'Archive client',
    create_super_admin_event: 'Create super admin',
    confirm_renewal: 'Confirm renewal event',
  };

  return (
    <div style={{ width: '100%', height: '200px', overflowY: 'auto' }}>
      <p className='label-title'>Event log</p>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Event name</th>
            <th>Status</th>
            <th>Status description</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((eventLogItem) => (
              <tr>
                <td>{humanNames[eventLogItem.eventName] ?? eventLogItem.eventName}</td>
                <td>{eventLogItem.status}</td>
                <td>{eventLogItem.statusDescription}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TenantEventLog;
