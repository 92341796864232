import React from 'react';

import TemplatesNavigationMenu from './components/TemplatesNavigationMenu/TemplatesNavigationMenu';
import TemplatesSubPagesContainer from './components/TemplatesSubPagesContainer/TemplatesSubPagesContainer';

const Templates = () => (
  <div className='m-3'>
    <div className='panel d-flex clients-page p-0' style={{ minHeight: '100vh', height: '100vh' }}>
      <TemplatesNavigationMenu />
      <TemplatesSubPagesContainer />
    </div>
  </div>
);

export default Templates;
