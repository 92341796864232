import React from 'react';

import Table from './Table';

const Translations = () => (
  <div className='m-3' style={{ minHeight: '100vh', height: '100vh' }}>
    <Table />
  </div>
);

export default Translations;
