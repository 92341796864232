import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './CheckBox.module.scss';
import FieldWrapper from 'components/FieldWrapper';

const CheckBox = ({
  id,
  name,
  value,
  label,
  subLabel,
  checked,
  onChange,
  className,
  labelClass,
  likeRadio,
  isIndeterminate,
  error,
  onBlur,
  color,
  shape,
  disableTranslation,
  isDisabled,
  disableAutoScroll,
  ariaLabel,
}) => {
  const handleChange = (event) => {
    event.stopPropagation();
    onChange(event.target.checked, event.target.name, event.target.value);
  };

  const onBlurField = () => {
    if (onBlur) {
      onBlur(name);
    }
  };

  return (
    <FieldWrapper
      name={name}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cx(className, isDisabled ? 'no-events' : '')}
    >
      <input
        data-testid={ariaLabel || id || value}
        className={cx(styles.inputField, color ? styles[color] : '', {
          [styles.invalid]: !!error,
          [styles.disableAutoScroll]: disableAutoScroll,
          [styles.square]: shape === 'square',
        })}
        id={id || value}
        type='checkbox'
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        onBlur={onBlurField}
        disabled={isDisabled}
      />

      <label
        className={cx(styles.label, labelClass, 'd-flex', 'align-items-center', {
          [styles.likeRadio]: likeRadio,
          [styles.indeterminate]: isIndeterminate,
        })}
        htmlFor={id || value}
      >
        {label && (
          <span className='ms-2 w-100 text-ellipsis'>
            {label}
            {subLabel && (
              <>
                <br />
                <span className={styles.subLabel}>{subLabel}</span>
              </>
            )}
          </span>
        )}
      </label>
    </FieldWrapper>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disableAutoScroll: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  likeRadio: PropTypes.bool,
  labelClass: PropTypes.string,
  color: PropTypes.string,
  disableTranslation: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  id: PropTypes.string,
  subLabel: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  checked: undefined,
  onChange: undefined,
  onBlur: undefined,
  className: '',
  likeRadio: false,
  disableAutoScroll: false,
  label: '',
  labelClass: '',
  color: '',
  disableTranslation: false,
  isIndeterminate: false,
  id: '',
  subLabel: '',
  error: '',
  isDisabled: false,
};

export default CheckBox;
