import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Modal from 'react-modal';

import styles from './Announcements.module.scss';
import AnnouncementModal from './components/AnnouncementModal';
import MobReleasePage from './mobRelease/MobReleasePage';
import WebReleasePage from './webRelease/WebReleasePage';
import AnnouncementsApi from '../api/AnnouncementsApi';
import modalStyle from '../components/modalStyles';

const editModalStyle = _.cloneDeep(modalStyle);
editModalStyle.content.width = '800px';
editModalStyle.content.padding = '0';

const Announcements = () => {
  const [activePage, setActivePage] = useState('web');
  const [addMobModal, setAddMobModal] = useState(false);
  const [addWebModal, setAddWebModal] = useState(false);
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const selectPage = (page) => {
    setActivePage(page);
    setAnnouncementModal(false);
  };

  // const selectReleaseNotesType = (type) => {
  //     if (type === 'mob') {
  //         setAddMobModal(true);
  //     } else {
  //         setAddWebModal(true);
  //     }
  //     setActivePage(type);
  //   };

  const onSave = (isEdit, data) => {
    setSaving(true);
    if (isEdit) {
      AnnouncementsApi.updateAnnouncement(selectedAnnouncement.id, data)
        .then(() => {
          setSaving(false);
          setSaved(true);
          setErrorMessage('');

          setTimeout(() => {
            setSaved(false);
            setAnnouncementModal(false);
            setSelectedAnnouncement(null);
          }, 1000);
        })
        .catch((err) => {
          setSaving(false);

          if (err?.response?.data?.message) {
            setErrorMessage(err.response.data.message);
          } else if (err?.response?.data?.detail) {
            setErrorMessage(err.response.data.detail);
          } else {
            setErrorMessage('Error! Something went wrong!');
          }
        });
    } else {
      // clear id's from items
      // eslint-disable-next-line no-restricted-syntax
      for (const item of data.announcementItems) {
        delete item.id;
      }
      AnnouncementsApi.createAnnouncement(data)
        .then(() => {
          setSaving(false);
          setSaved(true);
          setLoading(true);
          setErrorMessage('');
          setTimeout(() => {
            setLoading(false);
            setSaved(false);
            setAnnouncementModal(false);
            setSelectedAnnouncement(null);
          }, 1000);
        })
        .catch((err) => {
          setSaving(false);
          let msg = '';
          if (err?.response?.data?.message) {
            msg = err.response.data.message;
          } else if (err?.response?.data?.detail) {
            msg = err.response.data.detail;
          } else {
            msg = 'Error! Something went wrong!';
          }

          if (msg.trim() === 'Bad Request') {
            msg = 'Announcement already exists in predefined period';
          }

          setErrorMessage(msg);
        });
    }
  };

  return (
    <>
      <div className='m-3'>
        <div className={cx(styles.wrapper, 'panel clients-page p-0')}>
          <div className={cx(styles.header, 'row clients-page-header px-5 py-4')}>
            <div className='col-4'>
              <h2>Release notes</h2>
              <p className='tab-subtitle'>PalmHR releases shared with clients</p>
            </div>
            <div className='d-flex col-4 align-items-center justify-content-center'>
              <button
                onClick={() => {
                  selectPage('web');
                }}
                type='button'
                className={cx(styles.pageTab, 'py-1 px-2a me-3', activePage === 'web' && styles.active)}
              >
                web
              </button>
              <button
                onClick={() => {
                  selectPage('mob');
                }}
                type='button'
                className={cx(styles.pageTab, 'py-1 px-2a me-3', activePage === 'mob' && styles.active)}
              >
                mob
              </button>
            </div>
            <div className='col-4 d-flex align-items-center'>
              <div className='d-flex justify-content-end align-items-center w-100'>
                <button
                  type='button'
                  onClick={() => {
                    setSelectedAnnouncement(null);
                    setAnnouncementModal(true);
                  }}
                  className='btn btn-primary text-white'
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <i className='icon-plus me-2' />
                    <span>Add New</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className={cx(styles.content, 'row bg-white')}>
            <div className='w-100 position-relative h-100'>
              {loading ? null : (
                <div>
                  {activePage === 'web' ? (
                    <WebReleasePage
                      addWebModal={addWebModal}
                      onClose={() => {
                        setAddWebModal(false);
                      }}
                    />
                  ) : (
                    <MobReleasePage
                      addMobModal={addMobModal}
                      onClose={() => {
                        setAddMobModal(false);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={announcementModal}
        contentLabel='Add / Edit Announcement'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={editModalStyle}
        onRequestClose={() => {
          setAnnouncementModal(false);

          setTimeout(() => {
            setSelectedAnnouncement(null);
          }, 500);
        }}
      >
        <AnnouncementModal
          editData={selectedAnnouncement}
          onSave={onSave}
          onClose={() => {
            setAnnouncementModal(false);

            setTimeout(() => {
              setSelectedAnnouncement(null);
            }, 500);
          }}
          saving={saving}
          saved={saved}
          errorMessageOnSave={errorMessage}
        />
      </Modal>
    </>
  );
};

export default Announcements;
