import React from 'react';

import IconButton from '../../../../components/IconButton';
import SpinnerRing from '../../../../components/SpinnerRing';
import SuccessCheckmark from '../../../../components/SuccessCheckmark';

const AddToUnbilled = ({ onClose, onConfirm, count, saving, saved }) => (
  <div className='pt-4 ps-5'>
    {saving && <SpinnerRing />}
    {saved && <SuccessCheckmark />}
    <div className='d-flex justify-content-between border-btm pt-0 px-0 pb-3 me-5 ms-1'>
      <h5 className='ps-0 pe-3 modal-title fw-bold'>Add device to unbilled charges</h5>
      <div className='p-0 d-flex justify-content-end'>
        <IconButton icon='icon-cross-x' onClick={onClose} />
      </div>
    </div>
    <div>
      <div>
        <div className='pe-5 pt-5'>
          <p className='fw-bold mt-1 mb-3'>Number of unbilled devices: {count}</p>
          <p className='mt-1 mb-3'>Do you want to add all unbilled devices to unbilled charges?</p>
        </div>
      </div>
      <div className='d-flex justify-content-end border-t py-4 mt-1 me-5'>
        <button className='btn btn btn-secondary me-5' onClick={onClose} type='button'>
          Close
        </button>
        <button className='btn btn-primary text-white' onClick={onConfirm} type='button'>
          Confirm
        </button>
      </div>
    </div>
  </div>
);

export default AddToUnbilled;
