import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars-2';
import Modal from 'react-modal';

import styles from './Details.module.scss';
import GenerateProration from './GenerateProration';
import ClientsApi from '../../../../api/ClientsApi';
import modalStyles from '../../../../components/modalStyles';
import TableSkeleton from '../../../../components/skeletons/TableSkeleton';
import Tooltip from '../../../../components/Tooltip';
import formatCurrency from '../../../../utility/formatCurrency';
import ClientContext from '../ClientContext';
import { PACKAGE_NAMES } from '../../../constants';
import EmptyState from 'components/EmptyState';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '580px';

const Details = ({ detailsEmployees }) => {
  const { selected } = useContext(ClientContext);
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [clickedOnCopy, setClickedOnCopy] = useState(false);

  useEffect(() => {
    if (selected) {
      setLoadingData(true);
      const param = selected.id;
      ClientsApi.getTenant(param)
        .then((res) => {
          setData(res.data);
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
  }, [selected]);

  if (loadingData) {
    return (
      <div className='me-5'>
        <TableSkeleton lineHight={220} mb='mb-4' num={2} />
      </div>
    );
  }

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setClickedOnCopy(true);

    setTimeout(() => {
      setClickedOnCopy(false);
    }, 1000);
  };
  return (
    <div>
      {data?.customerData?.primarySubscription?.numberOfUnits < detailsEmployees &&
        data?.subscription?.plan?.includes('pro') && (
          <div className={styles.invoiceBtn}>
            <button
              type='button'
              className='btn btn-outline-secondary'
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Generate Proration
            </button>
          </div>
        )}
      <Scrollbars autoHide className='scroll-bar' style={{ height: 'calc(100vh - 215px)' }}>
        <div className='d-flex justify-content-between align-items-start'>
          <div className='w-50 border rounded p-3 mt-3' style={{ height: '320px' }}>
            <span className='fw-bold mt-1'>Subscription Details</span>
            <div className='d-flex justify-content-between flex-column gap-2 mt-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='fw-bold font-size-12 text-gray mb-2'>Status</p>
                <span className={cx(styles.status, styles[_.camelCase(data?.status)], 'mb-2')}>
                  {_.upperCase(data?.status)}
                </span>
              </div>
              <div className='d-flex justify-content-between  align-items-center '>
                <span className={cx(styles.label)}>Plan name</span>
                <span className={cx(styles.values)}>
                  {PACKAGE_NAMES[data?.details?.planName] || data?.details?.planName || '- -'}
                </span>
              </div>

              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>
                  <span>Charged units</span>
                  <span className='tooltip-box'>
                    <i className='icon-info text-gray ms-2' />
                    <Tooltip label='Number of employees paid for' />
                  </span>
                </span>
                <span className={cx(styles.values)}>{data?.details?.chargedUnits || '- -'}</span>
              </div>
              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>
                  <span>Effective units</span>
                  <span className='tooltip-box'>
                    <i className='icon-info text-gray ms-2' />
                    <Tooltip label='Current number of employees' />
                  </span>
                </span>
                <span className={cx(styles.values)}>{data?.details?.effectiveUnits || '- -'}</span>
              </div>

              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>Unit Price</span>
                <span className={cx(styles.values)}>
                  {data?.details?.unitPrice
                    ? `${data?.details?.currency} ${formatCurrency(data?.details?.unitPrice)}`
                    : '- -'}
                </span>
              </div>
              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>Price</span>
                <span className={cx(styles.values)}>
                  {data?.details?.price ? `${data?.details?.currency} ${formatCurrency(data?.details?.price)}` : '- -'}
                </span>
              </div>
              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>Next payment</span>
                <span className={cx(styles.values)}>
                  {data?.details?.nextPayment ? moment(data?.details?.nextPayment).format('MMM DD, YYYY') : '- -'}
                </span>
              </div>
              <div className='d-flex justify-content-between  align-items-center'>
                <span className={cx(styles.label)}>Created at</span>
                <span className={cx(styles.values)}>
                  {data?.details?.createdAt ? moment(data?.details?.createdAt).format('MMM DD, YYYY') : '- -'}
                </span>
              </div>
            </div>
          </div>

          <div className='w-50 border rounded p-3 mx-5 mt-3' style={{ height: '320px' }}>
            <p className='fw-bold mt-1'>Company Responsible</p>
            <p className='fw-bold font-size-12 text-gray mt-4 mb-4'>Primary Representative</p>
            <div className='d-flex justify-content-start flex-column gap-2 w-100'>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Name</span>
                <span className={cx(styles.values)}>
                  {data?.firstName || '-'} {data?.lastName || '-'}
                </span>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Country</span>
                <span className={cx(styles.values)}>{data?.country || '--'}</span>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Work email</span>
                <div className='text-steal d-flex align-items-start'>
                  <span className={cx(styles.values)}>
                    <a href={`mailto:${data?.details?.workEmail}`}>{data?.details?.workEmail || '- -'}</a>
                  </span>
                  {data?.details?.workEmail && (
                    <div className={cx('ms-2', 'text-gray')}>
                      <div className='tooltip-box'>
                        <i
                          className='icon-copy'
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(data?.details?.workEmail);
                          }}
                        />
                        <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} position={'left'} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Phone</span>
                <div className='text-steal d-flex align-items-start'>
                  <span className={cx(styles.values)}>{data?.phone || '- -'}</span>
                  {data?.phone && (
                    <div className={cx('ms-2', 'text-gray')}>
                      <div className='tooltip-box'>
                        <i
                          className='icon-copy'
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(data?.phone);
                          }}
                        />
                        <Tooltip label={clickedOnCopy ? 'Copied' : 'Click to copy'} position={'left'} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Job Title</span>
                <span className={cx(styles.values)}>{data?.jobTitle || '- -'}</span>
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <span className={cx(styles.label)}>Department</span>
                <span className={cx(styles.values)}>{data?.department || '- -'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-start my-3'>
          <div className='w-50 border rounded p-2'>
            <p className='fw-bold mb-3 mt-1'>Coupons</p>
            <div className={cx(styles.couponsContainer, 'd-flex justify-content-start flex-column gap-2 w-100')}>
              {data?.details?.coupons && data?.details?.coupons?.length > 0 ? (
                data.details.coupons.map((coupon) => (
                  <div className='d-flex justify-content-between align-items-center' key={coupon.id}>
                    <span className={cx(styles.label)}>Coupon Name</span>
                    <span className={cx(styles.values)}>{coupon.id}</span>
                  </div>
                ))
              ) : (
                <EmptyState
                  brandImage='empty-dolar.png'
                  title='No coupons'
                  subtitle='There are no available coupons'
                  showBtn={false}
                />
              )}
            </div>
          </div>
          <div className='w-50 border rounded p-2 mx-5'>
            <p className='fw-bold mb-3 mt-1'>Addons</p>
            <div className={cx(styles.addonsContainer, 'd-flex justify-content-start flex-column gap-2 w-100')}>
              {data?.details?.addons && data?.details?.addons?.length > 0 ? (
                data.details.addons.map((addon) => (
                  <div key={addon.id}>
                    <div className='d-flex justify-content-between align-items-center' key={addon.id}>
                      <span className={cx(styles.label)}>Addon Name</span>
                      <span className={cx(styles.values)}>{PACKAGE_NAMES[addon.name] || addon.name}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center' key={addon.id}>
                      <span className={cx(styles.label)}>Price</span>
                      <span className={cx(styles.values)}>{formatCurrency(addon.price)}</span>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState
                  brandImage='empty-dolar.png'
                  title='No addons'
                  subtitle='There are no additional addons'
                  showBtn={false}
                />
              )}
            </div>
          </div>
        </div>
      </Scrollbars>
      <Modal
        isOpen={openModal}
        contentLabel='Preview'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setOpenModal(false);
        }}
      >
        <GenerateProration
          units={selected?.numberOfEmployees}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Details;
