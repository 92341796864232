import React, { useState } from 'react';
import {
  initializeValues,
  isInsert,
  shouldLoadFromAPI,
  wizardSteps,
  editWizardSteps,
  getCustomerData,
} from '../../utility';
import WizardModal from '../WizardModal/WizardModal';
import { useQueryClient } from '@tanstack/react-query';
import { useMutationAddCustomer, useMutationUpdateCustomer } from 'Customers/hooks/useCustomers';

const AddCustomerModal = ({ isOpen, data, onClose }) => {
  const queryClient = useQueryClient();
  const wizardIsInsert = isInsert(data);
  const wizardShouldFetch = shouldLoadFromAPI(data);
  const wizardInitValues = initializeValues(wizardIsInsert, wizardShouldFetch ? {} : data);
  const { isLoading, mutate: addCustomerMutate } = useMutationAddCustomer();
  const { isLoading: lodaingUpdate, mutate: updateCustomerMutate } = useMutationUpdateCustomer();

  const onSubmit = (submitData, formikHelpers) => {
    const newCustomer = getCustomerData(submitData, wizardIsInsert);
    const mutateProcess = wizardIsInsert ? addCustomerMutate : updateCustomerMutate;
    mutateProcess(wizardIsInsert ? newCustomer : { id: data.id, data: newCustomer }, {
      onSuccess: async () => {
        if (wizardIsInsert) formikHelpers.resetForm();
        onClose();
        await queryClient.refetchQueries(['customers', 'getCustomerById']);
      },
    });
  };
  return (
    <WizardModal
      onClose={onClose}
      isOpen={isOpen}
      initialValues={wizardInitValues}
      onSubmit={onSubmit}
      title={wizardIsInsert ? 'New Customer' : 'Edit Customer'}
      isLoading={isLoading || lodaingUpdate}
      generateWizardStepsWithValidation={wizardIsInsert ? wizardSteps : editWizardSteps}
      submitButtonText={wizardIsInsert ? 'Add' : 'Save'}
      updateKey='accountType'
    />
  );
};

export default AddCustomerModal;
