/* eslint-disable indent */
/* eslint-disable no-duplicate-case */
/* eslint-disable quotes */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable comma-dangle */
import React, { useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

import EditMenu from './EditMenu';
import styles from './MobileTableRow.module.scss';
import fadeAnimation from '../../components/animations/fadeAnimation.module.scss';
import modalStyles from '../../components/modalStyles';
import OutsideClickHandler from '../../components/OutsideClickHandler';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.marginTop = '100px';

const editModalStyle = _.cloneDeep(modalStyles);
editModalStyle.content.width = '480px';
editModalStyle.content.padding = '0';
editModalStyle.content.overflow = 'visible';

const MobileTableRow = ({ data, onSelect, onEdit }) => {
  const [editOpened, setEditOpened] = useState(false);

  return (
    <tr className={cx(styles.wrapper)} onClick={onSelect}>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='text-steal'>{data.tenant}</div>
        <div>
          <a href={data.domain} target='_blank' rel='noreferrer noopener'>
            {data.domain}
          </a>
        </div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='text-steal'>{data.platform}</div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='text-steal'>{data.systemVersion}</div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='text-steal'>{data.model}</div>
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div className='text-steal'>{data.appVersion}</div>
      </td>
      <td className={cx(styles.values, 'pe-3 py-3')}>
        {data.createdAt ? moment(data.createdAt).format('MMM DD, YYYY') : '- -'}
      </td>
      <td className={cx(styles.values, 'py-3 pe-3')}>
        <div
          className='d-flex justify-content-between align-items-center me-3'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span
            className={cx(
              styles.status,
              styles[
                _.camelCase(
                  data.status === 'IN_REVIEW' ? 'review' : data.status === 'IN_PROGRESS' ? 'progress' : data.status
                )
              ]
            )}
          >
            {_.upperCase(data.status)}
          </span>

          <div className='position-relative'>
            <div className={cx(styles.moreBox, 'w-100', 'd-flex', 'align-items-center', 'justify-content-end')}>
              <i
                className={cx(styles.moreBtn, 'icon-more-vertical')}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditOpened(true);
                }}
              />
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={editOpened}
              timeout={200}
              classNames={{
                enter: fadeAnimation.enter,
                enterActive: fadeAnimation.enterActive,
                exit: fadeAnimation.exit,
                exitActive: fadeAnimation.exitActive,
              }}
            >
              <OutsideClickHandler
                clickHandler={() => {
                  setEditOpened(false);
                }}
              >
                <EditMenu
                  announcementId={data.id}
                  onEdit={() => {
                    onEdit(data.id);
                  }}
                />
              </OutsideClickHandler>
            </CSSTransition>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </td>
    </tr>
  );
};

MobileTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default MobileTableRow;
