import React from 'react';

import AsideNavLink from 'components/Navigation/AsideNavLink/AsideNavLink';

const TemplatesNavigationMenu = () => (
  <AsideNavLink
    className='col-12 col-md-4 col-lg-3 bg-light rounded'
    items={[
      {
        title: 'Implementation',
        href: '/templates/implementation',
      },
    ]}
  />
);

export default TemplatesNavigationMenu;
