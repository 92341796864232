export const periodUnitOptions = [
  {
    id: 'MONTH',
    name: 'Monthly',
    option: 'MONTH',
    value: 'MONTH',
  },
  {
    id: 'YEAR',
    name: 'Yearly',
    option: 'YEAR',
    value: 'YEAR',
  },
  {
    id: 'QUARTERLY',
    name: 'Quarterly',
    option: 'QUARTERLY',
    value: 'QUARTERLY',
  },
];

export const currencyOptions = [
  {
    id: 'EUR',
    name: 'EUR',
    option: 'EUR',
    value: 'EUR',
  },
  {
    id: 'USD',
    name: 'USD',
    option: 'USD',
    value: 'USD',
  },
  {
    id: 'SAR',
    name: 'SAR',
    option: 'SAR',
    value: 'SAR',
  },
];

export const PACKAGE_NAMES = {
  'PACKAGES.PALMHR.CORE.NAME': 'PalmHR Core',
  'PACKAGES.PALMHR.MULTI_COMPANY.NAME': 'Multi company feature',
  'PACKAGES.PALMHR.ATS.NAME': 'Applicant tracking system',
  'PACKAGES.PALMHR.BIOMETRICS.NAME': 'CAMS biometrics',
  'PACKAGES.PALMHR.ACCOUNTING.NAME': 'Accounting integrations',
  'PACKAGES.PALMHR.PREMIUM_SUPPORT.NAME': 'Premium support',
  'PACKAGES.PALMHR.CONCIERGE.NAME': 'Concierge',
  'PACKAGES.PALMHR.IMPLEMENTATION.NAME': 'Implementation',
};

export const initialfilterMapArray = [
  {
    title: 'Account Type',
    options: [
      { id: 1, field: 'accountType', label: 'Live Site', selected: false },
      { id: 2, field: 'accountType', label: 'Test Site', selected: false },
      { id: 3, field: 'accountType', label: 'Demo', selected: false },
    ],
  },
  {
    title: 'Account Status',
    options: [
      { id: 4, field: 'accountStatus', label: 'Active', selected: false },
      { id: 5, field: 'accountStatus', label: 'Pending', selected: false },
      { id: 6, field: 'accountStatus', label: 'Failed', selected: false },
      { id: 7, field: 'accountStatus', label: 'Suspended', selected: false },
      { id: 8, field: 'accountStatus', label: 'Archived', selected: false },
      { id: 9, field: 'accountStatus', label: 'Pending Suspension', selected: false },
    ],
  },
];
