import React, { useState } from 'react';

import { useFormik } from 'formik';

import IconButton from '../components/IconButton';

const AddTranslation = ({ onClose, addNew, selectedRow, editRow, errorMessage }) => {
  const [isSending, setIsSending] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.translationKey) {
      errors.translationKey = 'Key is required';
    }
    if (!values.english) {
      errors.english = 'English is required';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      translationKey: selectedRow?.translationKey || '',
      english: selectedRow?.english || '',
      arabic: selectedRow?.arabic || '',
      mobile: selectedRow?.mobile || false,
      context: selectedRow?.context,
      review: false,
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);
      if (selectedRow?.id) {
        editRow(selectedRow.id, values).then(() => {
          setIsSending(false);
        });
      } else {
        addNew(values);
        setIsSending(false);
      }
    },
  });

  const closeHandler = () => {
    if (isSending) {
      return;
    }
    onClose();
  };

  return (
    <div>
      <div>
        <div>
          <div className='py-4 px-5'>
            <div className='border-btm pt-0 px-0 d-flex justify-content-between pb-3'>
              <h5 className='modal-title fw-bold'>{selectedRow ? 'Edit translation' : 'Add translation'}</h5>
              <IconButton icon='icon-cross-x' onClick={closeHandler} />
            </div>
            <div className='px-0 pt-4'>
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className='form-group pb-3'>
                  <label htmlFor='key' className='label-title'>
                    Key
                    <sup>*</sup>
                  </label>
                  <input
                    type='text'
                    name='translationKey'
                    id='key'
                    placeholder='Key'
                    required
                    value={formik.values.translationKey}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`form-control ${
                      formik.errors.translationKey && formik.touched.translationKey ? 'is-invalid' : ''
                    }`}
                  />
                  <div className='invalid-feedback'>{formik.errors.translationKey}</div>
                </div>
                <div className='form-group pb-3'>
                  <label htmlFor='en' className='label-title'>
                    English
                    <sup>*</sup>
                  </label>
                  <textarea
                    rows='4'
                    id='en'
                    name='english'
                    value={formik.values.english}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    placeholder='English'
                    className={`form-control ${formik.errors.english && formik.touched.english ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{formik.errors.english}</div>
                </div>
                <div className='form-group pb-3'>
                  <label htmlFor='ar' dir='rtl' className='label-title text-right w-100'>
                    عربى
                    <sup>*</sup>
                  </label>
                  <textarea
                    rows='4'
                    name='arabic'
                    className='form-control'
                    id='ar'
                    value={formik.values.arabic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder='عربى'
                    dir='rtl'
                  />
                </div>
                <div className='form-group pb-3'>
                  <label htmlFor='en' className='label-title'>
                    Context
                  </label>
                  <textarea
                    rows='4'
                    id='en'
                    name='context'
                    value={formik.values.context}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder='Context'
                    className={`form-control ${formik.errors.context && formik.touched.context ? 'is-invalid' : ''}`}
                  />
                  <div className='invalid-feedback'>{formik.errors.english}</div>
                </div>
                <div className='form-group d-flex'>
                  <div className='me-3'>
                    <input
                      className='custom-checkbox form-check-input'
                      type='checkbox'
                      checked={formik.values.mobile}
                      name='mobile'
                      id='mobile'
                      onChange={formik.handleChange}
                    />
                    <label className='custom-label' htmlFor='mobile'>
                      Mobile
                    </label>
                  </div>
                  <input
                    className='custom-checkbox form-check-input'
                    type='checkbox'
                    name='review'
                    id='review'
                    onChange={formik.handleChange}
                  />
                  <label className='custom-label' htmlFor='review'>
                    Review
                  </label>
                </div>

                <div className='d-block invalid-feedback'>{errorMessage}</div>

                <div className='d-flex justify-content-end py-0 mt-5'>
                  <button type='button' className='btn btn-secondary me-2' onClick={closeHandler} disabled={isSending}>
                    Close
                  </button>
                  {isSending ? (
                    <button className='btn btn-primary text-white' type='button' disabled>
                      <span className='spinner-border spinner-border-sm me-4 ms-1' role='status' aria-hidden='true' />
                      Sending...
                    </button>
                  ) : (
                    <button type='submit' className='btn btn-primary text-white'>
                      Save translation
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTranslation;
