import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import AsideContent from './Aside/AsideContent';
import styles from './DetailsModal.module.scss';
import MainContent from './MainContent';
import ClientsApi from '../../../api/ClientsApi';
import IconButton from '../../../components/IconButton';
import modalStyle from '../../../components/modalStyles';

const addModalStyle = _.cloneDeep(modalStyle);
addModalStyle.content.width = '560px';

const DetailsModal = ({ onClose, customer }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('details');
  const selectView = (v) => {
    setView(v);
  };
  const [realNumberOfEmployees, setRealNumberOfEmployees] = useState('');

  useEffect(() => {
    setLoading(true);
    ClientsApi.getTenant(customer.id)
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.content, 'position-relative')}>
        <div className={styles.btnClose}>
          <IconButton onClick={onClose} icon='icon-cross-x' size='xs' />
        </div>
        <div className='row row-small h-100'>
          <div className='col-3 p-0'>
            <AsideContent selectView={selectView} active={view} setRealEmployees={(n) => setRealNumberOfEmployees(n)} />
          </div>
          <div className='col-9 p-0'>
            <MainContent view={view} setView={selectView} realEmployees={realNumberOfEmployees} details={details} />
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

export default DetailsModal;
