import React from 'react';
import NewCustomerBasicInfoInputs from '../NewCustomerBasicInfo/NewCustomerBasicInfoInputs';
import CardsSkeleton from 'components/skeletons/CardsSkeleton';
import { useQueryGetCountries } from 'Customers/hooks/useCustomers';

const EditCustomerBasicInfo = (formik) => {
  const { isLoading, data } = useQueryGetCountries();
  return (
    <section className='w-100'>
      <h4 className='subtitle font-size-20 mt-7 mb-5'>Let&apos;s start with some of the basic information</h4>

      <section className='px-5 pt-5 full-border radius-16 mb-2'>
        <section className='d-flex justify-content-between align-items-center'>
          <section>
            <h6 className='subtitle font-size-16 mb-2'>Basic Info</h6>
            <p className='mb-4 color-secondary font-size-14 multiline'>Define some basic details about the company</p>
          </section>
        </section>
        {isLoading && <CardsSkeleton col={12} height={350} num={1} />}
        {!isLoading && <NewCustomerBasicInfoInputs formik={formik} countries={data?.data?.items} edit />}
      </section>
    </section>
  );
};

export default EditCustomerBasicInfo;
