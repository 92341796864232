import React from 'react';

import cx from 'classnames';

import styles from '../Feedback.module.scss';

const WebPage = () => (
  // **** Customers end **** //
  <div>
    <div className='d-flex justify-content-between my-3 mx-5' />
    <div className='pt-8 m-auto'>
      <div className={cx('m-auto')}>
        <div className={cx(styles.iconHistory, 'd-flex', 'justify-content-center', 'align-items-center', 'm-auto')}>
          <i className={cx(styles.icon, 'icon-housing')} />
        </div>
        <p className='mt-6 text-center text-capitalize'>Currently we do not track web feedbacks.</p>
      </div>
    </div>
  </div>
);
export default WebPage;
