/* eslint-disable indent */
/* eslint-disable no-duplicate-case */
/* eslint-disable quotes */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable comma-dangle */
import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './MobileTableRow.module.scss';
import modalStyles from '../../components/modalStyles';

const addModalStyle = _.cloneDeep(modalStyles);
addModalStyle.content.width = '640px';
addModalStyle.content.marginTop = '100px';

const editModalStyle = _.cloneDeep(modalStyles);
editModalStyle.content.width = '480px';
editModalStyle.content.padding = '0';
editModalStyle.content.overflow = 'visible';

const MobileTableRow = ({ data, onSelect }) => (
  <tr className={cx(styles.wrapper)} onClick={onSelect}>
    <td className={cx(styles.values, 'py-3 pe-3')}>
      <div className='text-steal'>{data.tenant}</div>
      <div>
        <a href={data.domain} target='_blank' rel='noreferrer noopener'>
          {data.domain}
        </a>
      </div>
    </td>
    <td className={cx(styles.values, 'py-3 pe-3')}>
      <div className='text-steal'>{data.platform}</div>
    </td>
    <td className={cx(styles.values, 'py-3 pe-3')}>
      <div className='text-steal'>{data.systemVersion}</div>
    </td>
    <td className={cx(styles.values, 'py-3 pe-3')}>
      <div className='text-steal'>{data.model}</div>
    </td>
    <td className={cx(styles.values, 'py-3 pe-3')}>
      <div className='text-steal'>{data.appVersion}</div>
    </td>
    <td className={cx(styles.values, 'pe-3 py-3')}>
      {data.createdAt ? moment(data.createdAt).format('MMM DD, YYYY') : '- -'}
    </td>
  </tr>
);

MobileTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default MobileTableRow;
