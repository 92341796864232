import React from 'react';
import InputsSectionContainer from '../InputsSectionContainer/InputsSectionContainer';
import CustomerSubdomainInput from './CustomerSubdomainInput';
import CustomerAccountTypeInput from './CustomerAccountTypeInput';

const CustomerSetupAccount = (formik) => (
  <section className='w-100'>
    <h4 className='subtitle font-size-20 mt-7 mb-3'>Now, let&apos;s setup the account</h4>
    <InputsSectionContainer
      className='my-2'
      title='Choose your subdomain'
      subTitle={
        'Enter your subdomain name with max 30 characters.\n' +
        'Allowed: lowercase letters, numbers or hyphen\n' +
        'System will create workspace: your_subdomain.palm.hr'
      }
    >
      <CustomerSubdomainInput formik={formik} />
    </InputsSectionContainer>
    <InputsSectionContainer
      className={`my-2 ${
        formik.errors.accountType && formik.touched.accountType ? 'border-danger' : ''
      }`}
      title='Account Type'
      subTitle={'Select the type of account for this customer'}
    >
      <CustomerAccountTypeInput formik={formik} />
    </InputsSectionContainer>
  </section>
);

export default CustomerSetupAccount;
