import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import CustomRoute from 'components/Navigation/CustomRoute/CustomRoute';

import TemplatesList from '../TemplatesList/TemplatesList';

const TemplatesSubPagesContainer = () => {
  const path = '/templates';

  return (
    <Routes>
      <Route
        path={'implementation/*'}
        element={<CustomRoute path={`${path}/implementation`} component={TemplatesList} />}
      />
      <Route path='' element={<Navigate to='implementation' replace />} />
    </Routes>
  );
};

export default TemplatesSubPagesContainer;
