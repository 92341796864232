import React from 'react';

import ImplementationTableRow from './ImplementationTableRow';
import ImplementationEmptyState from './ImplementationEmptyState';
import DataTable from 'components/DataTable/DataTable';

const headerCells = [
  { title: 'Task Name', className: 'p-3', accessor: 'taskName' },
  { title: 'Assignee', sortable: true, sortValue: 'assignee', className: 'p-3' },
  { title: 'Due Date', sortable: true, sortValue: 'dueDate', className: 'p-3' },
  { title: 'Task status', sortable: true, sortValue: 'status', className: 'p-3' },
  { title: 'Email satus', sortable: true, sortValue: 'emailStatus', className: 'p-3' },
  { title: ' ', className: 'p-3', accessor: '' },
];
const ImplementationTable = ({
  tasks,
  isLoading,
  onSort,
  onMoveItem,
  onFilter,
  updateTask,
  ref,
  isSelected,
  handleConfirmationOpen,
  templates,
  isDraggable,
  showDrag,
  isScroll,
  setIsAddTaskModalOpen,
  openSave,
}) => (
  <DataTable
    className=''
    data={tasks}
    isLoading={isLoading}
    columns={headerCells}
    headerAdditionalProps={{
      onSort,
    }}
    tableHeight={'550px'}
    isScroll={isScroll}
    isDraggable={isDraggable}
    onMoveItem={onMoveItem}
    TableRowComponent={ImplementationTableRow}
    rowAdditionalProps={{ updateTask, ref, isSelected, showDrag, handleConfirmationOpen,openSave }}
    EmptySearchComponent={() => (
      <ImplementationEmptyState
        onFilter={onFilter}
        templates={templates}
        setIsAddTaskModalOpen={setIsAddTaskModalOpen}
      />
    )}
  />
);

export default ImplementationTable;
