import http from './Api';

class FeedbacksApi {
  static getFeedbacks(channel) {
    return http.get(`/feedbacks/${channel}`);
  }

  static feedbackDetails(channel, id) {
    return http.get(`/feedbacks/${channel}/${id}`);
  }

  static editFeedbackStatus(channel, id, data) {
    return http.put(`/feedbacks/${channel}/${id}/status`, data);
  }
}

export default FeedbacksApi;
