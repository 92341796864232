import React from 'react';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';
import Scrollbars from 'react-custom-scrollbars';

import CardsSkeleton from 'components/skeletons/CardsSkeleton';
import CustomerCard from '../CustomerCard/CustomerCard';
import EmptyPageList from 'components/EmptyPageList/EmptyPageList';
import EmptyCustomersState from '../EmptyCustomersState/EmptyCustomersState';

const CustomersCards = ({ customersData, updatePaginationParams, pagination, isLoading, addNew, withSearch }) =>  (
    <>
      {isLoading && <CardsSkeleton col={3} height={289} num={4} />}
      {customersData.length === 0 && !isLoading && (
        <>
          {withSearch ? (
            <EmptyPageList
              title='No one matches your search'
              leadText='Try being less specific, or check your spelling'
            />
          ) : (
            <EmptyCustomersState onClick={addNew} />
          )}
        </>
      )}

      {customersData.length > 0 && !isLoading && (
        <Scrollbars
          className='scrollbarsWrapper'
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          style={{ height: `100%` }}
        >
          <div className='d-flex justify-content-start flex-wrap gap-5'>
            {customersData.length > 0 && customersData.map((customer) => <CustomerCard data={customer} />)}
          </div>
          <PHRPagination onPaginationAction={updatePaginationParams} pagination={pagination} />
        </Scrollbars>
      )}
    </>
  );

export default CustomersCards;
