import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { useFormik } from 'formik';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from './AddDevice.module.scss';
import BiometricDveicesApi from '../../../../api/BiometricDevicesApi';
import IconButton from '../../../../components/IconButton';
import SpinnerRing from '../../../../components/SpinnerRing';
import SuccessCheckmark from '../../../../components/SuccessCheckmark';
import Tooltip from '../../../../components/Tooltip';

const AddDevice = ({ onClose, onSave, saved, selectedDevice, tenant }) => {
  const [isSending, setIsSending] = useState(false);
  const [timeZone, setTimeZone] = useState([]);

  useEffect(() => {
    BiometricDveicesApi.timeZone()
      .then((res) => {
        setTimeZone(res.data.items);
      })
      .catch(() => {});
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Name is required.';
    }

    if (!values.serialNumber) {
      errors.serialNumber = 'Serial number is required.';
    }

    if (!values.protocol) {
      errors.protocol = 'Please select a device protocol.';
    }

    if (!values.token) {
      errors.token = 'Please add a device token generated on CAMS portal.';
    }
    if (!values.timeZone) {
      errors.timeZone = 'Please select a device time zone.';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedDevice?.name || '',
      serialNumber: selectedDevice?.serialNumber || '',
      protocol: selectedDevice?.protocol || '',
      token: selectedDevice?.token || '',
      notes: null,
      isAddedToUnbilledCharges: selectedDevice?.isAddedToUnbilledCharges || false,
      timeZone: selectedDevice?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    validate,
    onSubmit: (values) => {
      setIsSending(true);

      const sentData = { ...values };

      onSave(!!selectedDevice, sentData);
    },
  });

  return (
    <div className={styles.overfowX}>
      {isSending && <SpinnerRing />}
      {saved && <SuccessCheckmark />}
      <div>
        <div className='modal-content py-4 ps-5 position-relative'>
          <div className='d-flex row border-btm pt-0 px-0 pb-3 me-5 ms-1 justify-content-between'>
            <h5 className='ps-0 pe-3 modal-title fw-bold'>{selectedDevice ? 'Edit Device' : 'Add Device'}</h5>
            <div className='p-0'>
              <IconButton icon='icon-cross-x' onClick={onClose} />
            </div>
          </div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Scrollbars
              className='scroll-bar'
              autoHide
              renderView={({ style, ...props }) => <div {...props} style={{ ...style, overflowX: 'hidden' }} />}
              style={{ height: '62vh' }}
            >
              <input hidden='hidden' type='text' name='tenant' id='tenant' defaultValue={tenant} />
              <div className='pe-5'>
                <p className='fw-bold mb-2'>Device info</p>
                <div className='row pb-7'>
                  <div className={cx(styles.inputHeight, 'form-group col-12')}>
                    <label htmlFor='name' className='label-title'>
                      Name*
                    </label>
                    <input
                      type='text'
                      name='name'
                      id='name'
                      placeholder='Name'
                      required
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control newClientInputs ${
                        formik.errors.name && formik.touched.name ? 'is-invalid' : ''
                      }`}
                    />
                    <div className='invalid-feedback'>{formik.errors.name}</div>
                  </div>

                  <div className={cx(styles.inputHeight, 'form-group col-12')}>
                    <label htmlFor='serialNumber' className='label-title'>
                      Serial Number*
                    </label>
                    <input
                      type='text'
                      name='serialNumber'
                      id='serialNumber'
                      placeholder='Serial number'
                      required
                      value={formik.values.serialNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control newClientInputs ${
                        formik.errors.serialNumber && formik.touched.serialNumber ? 'is-invalid' : ''
                      }`}
                    />
                    <div className='invalid-feedback'>{formik.errors.serialNumber}</div>
                  </div>

                  <div className={cx(styles.inputHeight, 'form-group col-12')}>
                    <label htmlFor='token' className='label-title'>
                      Device token*
                      <span className='tooltip-box'>
                        <i className='icon-info text-gray ms-2' />
                        <Tooltip label='Generated on CAMS portal' />
                      </span>
                    </label>
                    <input
                      type='text'
                      name='token'
                      id='token'
                      placeholder='Device token'
                      required
                      value={formik.values.token}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`form-control newClientInputs ${
                        formik.errors.token && formik.touched.token ? 'is-invalid' : ''
                      }`}
                    />

                    <div className='invalid-feedback'>{formik.errors.token}</div>
                  </div>

                  <div className={cx(styles.inputHeight, 'form-group col-12')}>
                    <label htmlFor='protocol' className='label-title'>
                      Device Protocol*
                    </label>
                    <select
                      name='protocol'
                      required
                      onChange={formik.handleChange}
                      value={formik.values.protocol}
                      className={`form-control newClientInputs ${
                        formik.errors.protocol && formik.touched.protocol ? 'is-invalid' : ''
                      }`}
                      disabled={selectedDevice && formik.values.isAddedToUnbilledCharges ? 'disabled' : ''}
                    >
                      <option value='' />
                      <option value='CAMS'>CAMS</option>
                      <option value='OTHER'>OTHER</option>
                    </select>
                    <div className='invalid-feedback'>{formik.errors.protocol}</div>
                    {formik.values.protocol !== '' && (
                      <div className={styles.costNote}>
                        <p>
                          <strong>Licence cost:</strong> SAR 240.00 / 1 Year
                        </p>
                        <p>
                          <strong>Integraton cost:</strong>{' '}
                          {formik.values.protocol === 'CAMS' ? 'SAR 490.00' : 'SAR 790.00'}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className={cx(styles.inputHeight, 'form-group col-12')}>
                    <label htmlFor='timeZone' className='label-title'>
                      Device Time Zone*
                    </label>
                    <select
                      name='timeZone'
                      required
                      onChange={formik.handleChange}
                      value={formik.values.timeZone}
                      className={`form-control newClientInputs ${
                        formik.errors.timeZone && formik.touched.timeZone ? 'is-invalid' : ''
                      }`}
                    >
                      {timeZone.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <div className='invalid-feedback'>{formik.errors.timeZone}</div>
                  </div>

                  {!selectedDevice && (
                    <div className={cx(styles.inputHeight, 'form-group col-12  pt-3')}>
                      <label htmlFor='content' className='label-title'>
                        Note content
                        <sup>*</sup>
                      </label>
                      <textarea
                        rows='4'
                        id='content'
                        name='notes[0].content'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder='Note'
                        className='form-control'
                      />
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
            <div className='d-flex justify-content-end border-t pt-4 mt-1 me-5'>
              <button type='button' className='btn btn-secondary me-5' onClick={onClose} disabled={isSending}>
                Cancel
              </button>
              <button type='submit' className='btn btn-primary text-white'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDevice;
