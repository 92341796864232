import React from 'react';

import PropTypes from 'prop-types';
import './CustomCheckbox.module.scss';

const CustomCheckbox = ({ changeHandler, inputChecked, checkboxName }) => {
  const id = Math.random().toString(36).substring(3);

  return (
    <div className='custom-control position-relative p-0'>
      <input type='checkbox' className='custom-checkbox p-0' id={id} onChange={changeHandler} checked={inputChecked} />
      <label className='custom-label' htmlFor={id}>
        {checkboxName}
      </label>
    </div>
  );
};

CustomCheckbox.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  inputChecked: PropTypes.bool.isRequired,
  checkboxName: PropTypes.string.isRequired,
};

export default CustomCheckbox;
