import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Modal from '../../../components/Modal/Modal';
import AddTaskForm from '../../forms/AddTaskForm/AddTaskForm';
import { queryKeys, useImplementationTaskAdd } from '../../hooks/useTemplates';

const AddTasksModal = ({ isOpen, onClose }) => {
  const initialValues = {
    name: '',
    nameLocal: '',
    periodAmount: null,
    periodUnit: null,
    description: '',
    order: null,
  };
  const { mutate, isLoading } = useImplementationTaskAdd();
  const queryClient = useQueryClient();

  const handleSubmit = (taskData) => {
    mutate(taskData, {
      onSuccess: async () => {
        onClose();
        await queryClient.invalidateQueries({ queryKey: queryKeys.ImplementationTasks() });
      },
    });
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} title={'Add Task'} styles={{ width: '560px' }}>
      <AddTaskForm onSubmit={handleSubmit} isLoading={isLoading} initialValues={initialValues} />
    </Modal>
  );
};

export default AddTasksModal;
