import React from 'react';

import { PHRAsideNavLink } from '@palmhr/palmhr-ui-lib';
import PropTypes from 'prop-types';

const AsideNavLink = ({ items, className }) => <PHRAsideNavLink items={items} className={className} />;

export default AsideNavLink;

AsideNavLink.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

AsideNavLink.defaultProps = {
  className: 'w-100 h-100',
};
